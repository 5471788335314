import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
  Card,
  CardContent,
  Divider,
  Grid,
  Fade,
  Backdrop,
  Link,
} from "@mui/material";
import Loader from "../component/loader/Loader";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import AxiosInstance from "../api/AxiosInstance";
// import Mockdetails from "./Mockdetails";
import { toast } from "react-toastify";
import moment from "moment";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  // bgcolor: "white",
  // border: "1px solid #000",
  boxShadow: 30,
  // p: 4,
};

export default function RequirementCount(props) {
  let [regdStudents, setRegdStudents] = React.useState([]);
  const [defaultStudent, setDefaultStudent] = React.useState([]);
  const [loadStu, setLoadStu] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    limit: 25,
    page: 1,
    load: false,
    length: 1,
  });

  let level = props.quizAttendedLevel;
  let selectedParam = props.selectedParam;
  let appointment_id = props.appointment_idx;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const [eduopen, setEduOpen] = React.useState(false);
  const [reqopen, setReqOpen] = React.useState(false);

  const handleOpen1 = (modalType) => {
    if (modalType === "eduopen") {
      setEduOpen(true);
    } else if (modalType === "reqopen") {
      setReqOpen(true);
    }
  };

  const handleCloseEdu = () => setEduOpen(false);
  const handleCloseReq = () => setReqOpen(false);

  const [selectedAcademic, setSelectedAcademic] = React.useState(null);
  const [selectedRequirement, setSelectedRequirement] = React.useState(null);
  console.log(selectedRequirement, "req");

  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    // bgcolor: "white",
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
  };

  const [loadReg, setLoadRed] = React.useState(false);

  React.useEffect(() => {
    const fetchData = async () => {
      let orgID = `?organisation_id`;
      let QuizAttendedValue =
        level === "1"
          ? `${orgID}=${appointment_id}`
          : level === "2"
          ? `?branch_id=${appointment_id}`
          : level === "3"
          ? `?batch_id=${appointment_id}`
          : "";
      handleOpen();
      setLoadRed(true);
      const url1 = `/bd_manager/manager-req-list/`;
      const params= selectedParam?`&${selectedParam}`:""
      try {
        let response = await AxiosInstance.get(
          `${url1}${QuizAttendedValue}${params}`
        );
        console.log(response, "");
        setRegdStudents(response.data.results);
        setDefaultStudent(response.data.results);
        setPagination({
          ...pagination,
          length: response.data.total,
          load: response.data.total > 25 ? false : null,
          page: 1,
          limit: response.data.per_page,
        });
        // console.log(response,"response")
        setLoadRed(false);
      } catch (error) {
        setLoadRed(null);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (_, value) => {
    setPagination({
      ...pagination,
      page: value,
      load: true,
    });
    // setLoadRed(true);
    const newPage = value;
    let QuizAttendedValue =
      level === "1"
        ? `?organization_id=${appointment_id}`
        : level === "2"
        ? `?branch_id=${appointment_id}`
        : level === "3"
        ? `?batch_id=${appointment_id}`
        : "";
    setPagination({ ...pagination, load: true });
    const url1 = `/bd_manager/manager-req-list/`;

    try {
      const { data } = await AxiosInstance.get(
        `${url1}${QuizAttendedValue}&${selectedParam}&page=${newPage}`
      );

      setRegdStudents(data.results);
      // setLoadRed(false);

      setPagination({
        ...pagination,
        length: data.total,
        page: value,
        load: false,
      });
    } catch (error) {
      toast.error("something went wrong");
      setPagination({ ...pagination, load: false });
      // setLoadRed(false);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 10 + index + 1;
  };

  // const totalPages = Math.ceil(totalLength / 10);

  return (
    <main>
      {loadReg === null ? (
        <h3>Something went wrong</h3>
      ) : loadReg ? (
        "Loading..."
      ) : (
        <>
          {/* modal for Acadamic*/}
          <Modal keepMounted open={eduopen} onClose={handleCloseEdu}>
            <Box sx={style1}>
              <Card className="academic-card">
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "rgb(48, 143, 174)",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Academic Details
                  </Typography>
                  <Divider />
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <button
                      onClick={handleCloseEdu}
                      style={{
                        position: "absolute",
                        right: 0,
                        top: 0,
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                      }}
                    >
                      <svg
                        width="27.81px"
                        height="30px"
                        viewBox="0 0 1216 1312"
                      >
                        <path
                          fill="red"
                          d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                        ></path>
                      </svg>
                    </button>
                    <aside style={{ width: "50%" }}>
                      <Typography variant="body1" paragraph>
                        <strong>Class 10 Percentage:</strong>
                        <br />
                        {selectedAcademic?.tenth_percent
                          ? selectedAcademic?.tenth_percent
                          : "----"}
                        % <br />
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Class 12 Percentage:</strong>
                        <br />
                        {selectedAcademic?.twelfth_percent
                          ? selectedAcademic?.twelfth_percent
                          : "----"}
                        % <br />
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Diploma Percentage:</strong>
                        <br />
                        {selectedAcademic?.twelfth_percent
                          ? selectedAcademic?.twelfth_percent
                          : "----"}
                        % <br />
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Degree Percentage:</strong>
                        <br />
                        {selectedAcademic?.degree_percent
                          ? selectedAcademic?.degree_percent
                          : "----"}
                        % <br />
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Year Of Passing:</strong>
                        <br />
                        <span
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            display: "inline-block",
                            width: "100%",
                          }}
                        >
                          {" "}
                          {selectedAcademic?.yop ? (
                            <span
                              style={{
                                display: "inline-block",
                                width: "96%",
                                fontWeight: "400",
                                wordBreak: "break-word",
                              }}
                            >
                              {selectedAcademic?.yop.split(",").join(", ")}
                            </span>
                          ) : (
                            "----"
                          )}{" "}
                        </span>
                        <br />
                      </Typography>
                    </aside>
                    <aside>
                      <Typography variant="body1" paragraph>
                        <strong>Degree:</strong>
                        <br />
                        <span
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            display: "inline-block",
                          }}
                        >
                          {selectedAcademic?.degree.length > 0
                            ? selectedAcademic?.degree?.map((val,ind) => {
                                console.log(val, "werth");
                                return (
                                  <React.Fragment key={ind}>
                                    {val.name}
                                    <br />
                                  </React.Fragment>
                                );
                              })
                            : "No Data"}
                        </span>
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Degree Stream:</strong>
                        <br />
                        <span
                          style={{
                            maxHeight: "300px",
                            overflow: "auto",
                            display: "inline-block",
                          }}
                        >
                          {" "}
                          {selectedAcademic?.degree_stream.length > 0
                            ? selectedAcademic?.degree_stream?.map((val,ind) => {
                                return (
                                  <React.Fragment key={ind}>
                                    {val.name}
                                    <br />
                                  </React.Fragment>
                                );
                              })
                            : "No Data"}
                        </span>
                      </Typography>
                    </aside>
                  </div>
                </CardContent>
              </Card>
            </Box>
          </Modal>
          {/* modal for Requirement*/}
          <Modal
            open={reqopen}
            onClose={handleCloseReq}
            closeAfterTransition
            BackdropComponent={Backdrop}
            BackdropProps={{
              timeout: 500,
            }}
          >
            <Fade in={reqopen}>
              <Box sx={style}>
                {selectedRequirement === null ? (
                  <div>Loading...</div>
                ) : (
                  <Card className="academic-card">
                    <CardContent>
                      <button
                        onClick={handleCloseReq}
                        style={{ position: "absolute", right: 0, top: 0 }}
                      >
                        Back
                      </button>
                      <Typography
                        style={{
                          color: "rgb(48, 143, 174)",
                          fontSize: "30px",
                          fontWeight: "bold",
                          textAlign: "center",
                        }}
                      >
                        Requirement Details
                      </Typography>
                      <Typography>
                        <strong>Business Unit:</strong> &nbsp;&nbsp;
                        {selectedRequirement?.organisation.name
                          ? selectedRequirement?.organisation.name
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong> No. Of Positions :</strong>&nbsp;&nbsp;
                        {selectedRequirement?.position
                          ? selectedRequirement?.position
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong> Candidate Required:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.candidates_required
                          ? selectedRequirement?.candidates_required
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Gender:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.gender.length > 0
                          ? selectedRequirement?.gender?.map((val,ind) => {
                           
                              return val.name;
                             
                            })
                          : "No gender specified"}
                      </Typography>
                      <Typography>
                        <strong> Candidate Designation :</strong>&nbsp;&nbsp;
                        {selectedRequirement?.designation.length > 0
                          ? selectedRequirement?.designation?.map((val) => {
                              return val.name;
                            })
                          : "No Data"}
                      </Typography>
                      <Typography>
                        <strong> Interview Rounds:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.rounds.length > 0
                          ? selectedRequirement?.rounds?.map((val) => {
                              return val.name;
                            })
                          : "No Data"}
                      </Typography>
                      <Typography>
                        <strong>CTC:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.ctc
                          ? selectedRequirement?.ctc
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Mode Of Interview:</strong> &nbsp;&nbsp;
                        {selectedRequirement?.interview.name
                          ? selectedRequirement?.interview.name
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Mode Of Hiring:</strong> &nbsp;&nbsp;
                        {selectedRequirement?.m_hiring.name
                          ? selectedRequirement?.m_hiring.name
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong> Interview Date: </strong> &nbsp;&nbsp;
                        {moment(selectedRequirement.tentative_date).format(
                          "YYYY-MM-DD"
                        )
                          ? moment(selectedRequirement.tentative_date).format(
                              "YYYY-MM-DD"
                            )
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Interview Link:</strong> &nbsp;&nbsp;
                        <Link style={{ textDecoration: "none" }}>
                          {" "}
                          {selectedRequirement?.meeting_url
                            ? selectedRequirement?.meeting_url
                            : "----"}{" "}
                        </Link>
                      </Typography>
                      <Typography>
                        <strong> Job Location:</strong> &nbsp;&nbsp;
                        {selectedRequirement?.location.length > 0
                          ? selectedRequirement?.location?.map((val) => {
                              return val.name;
                            })
                          : "No Data"}
                      </Typography>
                      <Typography>
                        <strong>Interview Venue:</strong>
                        {selectedRequirement?.venue
                          ? selectedRequirement?.venue
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Bond: </strong> &nbsp;&nbsp;
                        {selectedRequirement?.bond ? "true" : "false"}
                      </Typography>

                      <Typography>
                        <strong>Certificates :</strong>&nbsp;&nbsp;
                        {selectedRequirement?.certificate.length > 0
                          ? selectedRequirement?.certificate?.map((val) => {
                              return val.name;
                            })
                          : selectedRequirement?.other === true
                          ? selectedRequirement?.other_certificate
                          : "No Data"}
                      </Typography>
                      <Typography>
                        <strong>Shift:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.shift.length > 0
                          ? selectedRequirement?.shift?.map((val) => {
                              return val.name;
                            })
                          : "No Data"}
                      </Typography>
                      <Typography>
                        <strong>Comment:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.comment
                          ? selectedRequirement?.comment
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Requirement TAT Date:</strong>&nbsp;&nbsp;
                        {moment(selectedRequirement.close_date).format(
                          "YYYY-MM-DD"
                        )
                          ? moment(selectedRequirement.close_date).format(
                              "YYYY-MM-DD"
                            )
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Spoc Number:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.contact_number
                          ? selectedRequirement?.contact_number
                          : "----"}
                      </Typography>
                      <Typography>
                        <strong>Other Skills:</strong>&nbsp;&nbsp;
                        {selectedRequirement?.other_skill
                          ? selectedRequirement?.other_skill
                          : "----"}
                      </Typography>
                    </CardContent>
                  </Card>
                )}
              </Box>
            </Fade>
          </Modal>
          {/* model for student details */}
          <Typography
            id="modal-modal-title"
            align="center"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "rgb(48, 143, 174)",
            }}
          >
            Student Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",

                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Company Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Requirement Details
                  </TableCell>

                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Academic Details
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Organization
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Added Count/Required Count
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Gotjob Count
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      // backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Bd Name
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {pagination.load ? (
                  <div
                    style={{
                      width: "80vw",
                      height: "40vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : regdStudents.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>No Data Found</TableCell>
                  </TableRow>
                ) : (
                  regdStudents?.map((value, index) => {
                    // console.log(value, "value");
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={index}
                      >
                        <TableCell align="center">
                          {calculateSlNo(index)}
                        </TableCell>
                        <TableCell align="center">
                          {value.company_details_name}
                        </TableCell>

                        {/* //! Requirement details */}
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setSelectedRequirement(value);
                              handleOpen1("reqopen");
                            }}
                          >
                            <svg  width="30px" height="30px" viewBox="0 0 26 26"><path fill="black" d="M4 0C1.8 0 0 1.8 0 4v17c0 2.2 1.8 4 4 4h11c.4 0 .7-.094 1-.094c-1.4-.3-2.594-1.006-3.594-1.906H4c-1.1 0-2-.9-2-2V4c0-1.1.9-2 2-2h6.313c.7.2.687 1.1.687 2v3c0 .6.4 1 1 1h3c1 0 2 0 2 1v1h.5c.5 0 1 .088 1.5.188V8c0-1.1-.988-2.112-2.688-3.813c-.3-.2-.512-.487-.812-.687c-.2-.3-.488-.513-.688-.813C13.113.988 12.1 0 11 0zm13.5 12c-3 0-5.5 2.5-5.5 5.5s2.5 5.5 5.5 5.5c1.273 0 2.435-.471 3.375-1.219l.313.313a.955.955 0 0 0 .125 1.218l2.5 2.5c.4.4.975.4 1.375 0l.5-.5c.4-.4.4-1.006 0-1.406l-2.5-2.5a.935.935 0 0 0-1.157-.156l-.281-.313c.773-.948 1.25-2.14 1.25-3.437c0-3-2.5-5.5-5.5-5.5m0 1.5c2.2 0 4 1.8 4 4s-1.8 4-4 4s-4-1.8-4-4s1.8-4 4-4"></path></svg>
                          </Button>
                        </TableCell>
                        {/* //! Academic details */}
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setSelectedAcademic(value);
                              handleOpen1("eduopen");
                            }}
                          >
                            <SchoolSharpIcon style={{ color: "black" }} />
                          </Button>
                        </TableCell>

                        <TableCell align="center">
                          {value.organisation.name || "no data"}
                        </TableCell>
                        <TableCell align="center">
                          <span>{value.student_count || "no data"}</span>/
                          <span>{value.requirement || "no data"}</span>
                        </TableCell>
                        <TableCell align="center">
                          {value.count.gj_count || "no data"}
                        </TableCell>
                        <TableCell align="center">
                          {value.author.first_name || "no data"}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <aside
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1%",
              marginBottom: "1%",
            }}
          >
            {pagination.load !== null && (
              <Pagination
                count={Math.ceil(pagination.length / pagination.limit)}
                color="primary"
                defaultPage={1}
                disabled={pagination.load}
                rowsperpage={pagination.limit}
                siblingCount={1}
                showLastButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                showFirstButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                page={pagination.page}
                onChange={handleChangePage}
              />
            )}
          </aside>
        </>
      )}
    </main>
  );
}
