import { createContext, useCallback, useEffect, useState } from "react";
import AxiosInstance from "../api/AxiosInstance";
import moment from "moment";

export const PostContext = createContext();

export const PostContextProvider = ({ children }) => {
  const [mainTableQuery, setMainTableQuery] = useState({
    number: "",
    email: "",
    student_name:""
  });


  const clearData = () => {
    setLoadedOrg([]);
    setLoadedBatches([]);
    setLoadedBranches([]);
    setOrganization({
      data: [],
      loading: false,
      loadMore: false,
      error: null,
      length: 0,
    });
    setBranches([]);
    setToggledNode([]);
    setBranches(null);
    setBatches(null);
  };
  //!new logic
  const [loadedOrg, setLoadedOrg] = useState([]);
  const [queryOrg, setQueryOrg] = useState(null);
  const [organization, setOrganization] = useState({
    data: [],
    loading: false,
    loadMore: false,
    error: null,
    length: 0,
  });

  console.log("queryOrg", queryOrg);

  useEffect(() => {
    let fetchOrganization = async () => {
      let url = "/report/orgreport/";
      if (queryOrg) {
        url += `?${queryOrg}`;
      }
      try {
        const { data } = await AxiosInstance.get(url);
        setOrganization({
          ...organization,
          data: data.results && data.results.length > 0 ? data.results : null,
          loading: false,
          length: data.total,
        });
        const newKeys = data.results.map((e) => e.id);
        if (newKeys.length > 0) {
          setBranches(createLayer(branches, newKeys));
        }
      } catch (error) {
        setOrganization({
          ...organization,
          data: null,
          error: error.response ? error.response.data.message : "Network issue",
          loading: false,
        });
      }
    };

    fetchOrganization();
  }, [queryOrg]);
  // Reset for tree
  // console.log(branch,"branch")
  const handleTreereset = () => {
    setQueryOrg(null);
  };

  const createLayer = (state, newKeys) => {
    if (state) {
      const uniqueKeys = newKeys.filter((e) => !Object.keys(state).includes(e));
      const obj = {};
      uniqueKeys.forEach((e) => {
        obj[e] = {
          data: [],
          loading: false,
          loadMore: false,
          error: null,
          length: 0,
        };
      });
      return { ...state, ...obj };
    } else {
      const obj = {};
      newKeys.forEach((e) => {
        obj[e] = {
          data: [],
          loading: false,
          loadMore: false,
          error: null,
          length: 0,
        };
      });
      return obj;
    }
  };

  const fetchMoreOrg = async () => {
    setOrganization({ ...organization, loadMore: true });
    const page = Math.ceil(organization.data.length / 10) + 1;
    try {
      const { data } = await AxiosInstance.get(`/report/orgreport/`);
      setOrganization({
        ...organization,
        data:
          data.results.length > 0
            ? [...organization.data, ...data.results]
            : organization.data,
        loadMore: false,
        length: data.total,
      });
      const newKeys = data.results.map((e) => e.id);
      if (newKeys.length > 0) {
        setBranches(createLayer(branches, newKeys));
      }
    } catch (error) {
      setOrganization({
        ...organization,
        data: null,
        error: error.response ? error?.response?.data?.error : "Network issue",
        loading: false,
      });
    }
  };

  const [branches, setBranches] = useState(null);
  const [queryBatch, setQueryBatch] = useState(null);
  const [loadedBranches, setLoadedBranches] = useState([]);

  const [toggledNode, setToggledNode] = useState([]);

  const handleSelect = (x, y) => {
    const nodeId = y.split("@@")[1].split("#")[1];
    const nodeName = y.split("@@")[1].split("#")[0];
    if (nodeName === "subject" && !loadedOrg.includes(nodeId)) {
      fetchBranches(nodeId);
      setLoadedOrg([...loadedOrg, nodeId]);
    } else if (nodeName === "chapter" && !loadedBranches.includes(nodeId)) {
      fetchBatches(nodeId, y);
      setLoadedBranches([...loadedBatches, nodeId]);
    } else if (nodeName === "topic" && !loadedBatches.includes(nodeId)) {
      // fetchSubtopic(nodeId, y);
      setLoadedBatches([...loadedBatches, nodeId]);
    }
    // else if (nodeName === "subject" && loadedOrg.includes(nodeId)) {
    //   fetchBranches(nodeId);
    //   setLoadedOrg([...loadedOrg, nodeId]);
    // }
  };

  const fetchBranches = async (id) => {
    const updatedState = { ...branches };
    updatedState[id] = { ...updatedState[id], loading: true };
    setBranches(updatedState);
    let url1 = "/report/branchreport/";
    if (queryBatch) {
      url1 += `?${queryBatch}`;
    } else {
      url1 += `?organization_id=${id}`;
    }
    try {
      const { data } = await AxiosInstance.get(url1);
      const updatedState = { ...branches };
      updatedState[id] = {
        ...updatedState[id],
        loading: false,
        data: data.results.length > 0 ? data.results : null,
        length: data.total,
      };
      setBranches(updatedState);
      const newKeys = data.results.map((e) => e.id);
      if (newKeys.length > 0) {
        setBatches(createLayer(batches, [id]));
      }
    } catch (error) {
      const updatedState = { ...branches };
      updatedState[id] = {
        ...updatedState[id],
        loading: false,
        error: error.response ? error?.response?.data?.error : "Network issue",
        data: null,
      };
      setBranches(updatedState);
    }
  };

  //   fetch more branch
  const fetchMoreBranch = async (id) => {
    const page = Math.ceil(branches[id].data.length / 25) + 1;
    const payload = { ...branches };
    payload[id] = { ...payload[id], loadMore: true };
    setBranches({ ...payload });

    // console.log("payload", payload);
    try {
      const { data } = await AxiosInstance.get(
        `/report/branchreport/?organization_id=${id}&page=${page}`
      );
      const payload = { ...branches };
      payload[id] = {
        ...payload[id],
        data:
          data.total > 0
            ? [...payload[id].data, ...data.results]
            : payload[id].data,
        length: data.total,
        loadMore: false,
      };
      setBranches({ ...payload });
      const newKeys = data.results.map((e) => e.id);
      if (newKeys.length > 0) {
        setBatches(createLayer(batches, newKeys));
      }
    } catch (error) {
      console.log(error);
      const payload = { ...branches };
      payload[id] = {
        ...payload[id],
        loadMore: null,
        error: error.response ? error?.response?.data?.error : "Network issue",
      };
      setBranches({ ...payload });
    }
  };

  const [batches, setBatches] = useState(null);
  const [queryBatchCode, setQueryBatchCode] = useState(null);
  const [loadedBatches, setLoadedBatches] = useState([]);
  const fetchBatches = async (id) => {
    const updatedState = { ...batches };
    updatedState[id] = { ...updatedState[id], loading: true };
    setBatches(updatedState);
    let url2 = "/report/batchreport/";
    if (queryBatchCode) {
      url2 += `?${queryBatchCode}`;
    } else {
      url2 += `?branch_id=${id}`;
    }

    try {
      const { data } = await AxiosInstance.get(url2);
      const updatedState = { ...batches };
      updatedState[id] = {
        ...updatedState[id],
        loading: false,
        data: data.results.length > 0 ? data.results : null,
        length: data.total,
      };
      setBatches(updatedState);
      // const newKeys = data.results.map(e => e.id);
      // if (newKeys.length > 0) {
      //   setBatches(createLayer(batches, [id]));
      // }
    } catch (error) {
      const updatedState = { ...batches };
      updatedState[id] = {
        ...updatedState[id],
        loading: false,
        error: error.response ? error?.response?.data?.error : "Network issue",
        data: null,
      };
      setBatches(updatedState);
    }
  };

  // fetchmore for Btach
  const fetchMoreBatch = async (id) => {
    const page = Math.ceil(batches[id].data.length / 25) + 1;
    const payload = { ...batches };
    payload[id] = { ...payload[id], loadMore: true };
    setBatches({ ...payload });

    try {
      const { data } = await AxiosInstance.get(
        `/report/batchreport/?branch_id=${id}&page=${page}`
      );
      const payload = { ...batches };
      payload[id] = {
        ...payload[id],
        data:
          data.total > 0
            ? [...payload[id].data, ...data.results]
            : payload[id].data,
        length: data.total,
        loadMore: false,
      };
      setBatches({ ...payload });
    } catch (error) {
      console.log(error);
      const payload = { ...batches };
      payload[id] = {
        ...payload[id],
        loadMore: null,
        error: error.response ? error?.response?.data?.error : "Network issue",
      };
      setBatches({ ...payload });
    }
  };

  const [expanded, setExpanded] = useState([]);

  const [currentMonthData, setCurrentMonthData] = useState(null);
  useEffect(() => {
    const today = moment();
    const year = today.year();
    const month = today.month();

    const firstDay = moment([year, month, 1]);
    const lastDay = moment(firstDay).endOf("month");

    const firstDayFormatted = firstDay.format("YYYY-MM-DD");
    const lastDayFormatted = lastDay.format("YYYY-MM-DD");

    setCurrentMonthData({
      firstDay: firstDayFormatted,
      lastDay: lastDayFormatted,
    });
  }, []);

  return (
    <PostContext.Provider
      value={{
        expanded,
        setExpanded,
        organization,
        setOrganization,
        fetchMoreOrg,
        setBranches,
        fetchMoreBranch,
        fetchMoreBatch,
        setBatches,
        toggledNode,
        setToggledNode,
        handleSelect,
        branches,
        batches,
        currentMonthData,
        handleTreereset,
        queryOrg,
        setQueryOrg,
        setQueryBatch,
        mainTableQuery,
        setMainTableQuery,
        setQueryBatchCode,
        clearData
      }}
    >
      {children}
    </PostContext.Provider>
  );
};
