import axios from "axios";

let token = window.sessionStorage.getItem("token");
let AxiosInstance = axios.create({


  baseURL: "https://hrstbackend.qspiders.com", //local
  // baseURL: "http://192.168.1.220:8787", //testing



  headers: {
    "Content-type": "application/json",

    Authorization: `Token ${token}`,
  },
});
export default AxiosInstance;