
import React, { useState } from 'react';
import Notification from './component/auth/Notification';
import AxiosInstance from './api/AxiosInstance';
import axios from 'axios';


export let GlobalContexData = React.createContext();

const GlobalContext = ({ children }) => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [resData, setResData] = useState();
  const [showPassword, setShowPassword] = useState(false);
  const [notification, setNotification] = useState(null);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    setPassword(e.target.value);
  };
 
  const handleCloseNotification = () => {
    setNotification(null);
  };


  const handleLogin = async (e) => {
    e.preventDefault();
console.log({"username": username,
"password": password,})
    try {
      const response = await axios.post('https://hrstbackend.qspiders.com/users/report_analyst/login/', {
        "username": username,
        "password": password,
      });
      console.log(response.data);
      sessionStorage.setItem('token',response.data.token)
      setResData(response.data);

setNotification({ message: 'Login successful!', type: 'success' });
window.location.assign(`/report`);
} catch (error) {
  // Handle login error
  console.error('Login failed', error);

  // Show an error notification
  setNotification({ message:  "Unable to log in with provided credentials.", type: 'error' });
}
};

  return (
    <GlobalContexData.Provider value={{
      username,
      resData,
      password,
      showPassword,
      setPassword,
      setUsername,
      handleClickShowPassword,
      handleMouseDownPassword,
      handleLogin,
    }}>
      {children}

      {notification && (
        <Notification
          message={notification.message}
          type={notification.type}
          onClose={handleCloseNotification}
        />
      )}
    </GlobalContexData.Provider>
  );
};

export default GlobalContext;

