import React, { useEffect } from 'react';

const Notification = ({ message, type, onClose }) => {
  useEffect(() => {
    const timer = setTimeout(() => {
      onClose();
    }, 3000); // Close the notification after 3 seconds

    return () => clearTimeout(timer);
  }, [onClose]);

  return (
    <div style={{width:"20%", position: 'fixed', top: '10px', right: '10px', padding: '10px',background: type === 'success' ? 'green' : 'red', color: 'white' }}>
      {message}
    </div>
  );
};

export default Notification;
