import React, { useState, useContext } from "react";
// import axios from 'axios';
import styles from "./Login.module.css";
// import { useNavigate } from "react-router-dom";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { GlobalContexData } from "../../GlobalContext";

const Login = () => {
  const { setUsername, setPassword, handleLogin } =
    useContext(GlobalContexData);

  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (e) => {
    setPassword(e.target.value);
  };

  return (
    <section id={styles.LoginFomrContainer}>
      <form className={styles.loginform} onSubmit={handleLogin}>
        <aside>
          <svg width="10vh" height="60px" viewBox="0 0 20 20">
            <path
              fill="rgb(48, 143, 174)"
              d="M10 0c5.52 0 10 4.48 10 10s-4.48 10-10 10S0 15.52 0 10S4.48 0 10 0m0 .5C4.75.5.5 4.75.5 10s4.25 9.5 9.5 9.5s9.5-4.25 9.5-9.5S15.25.5 10 .5m0 1c4.7 0 8.5 3.8 8.5 8.5s-3.8 8.5-8.5 8.5s-8.5-3.8-8.5-8.5S5.3 1.5 10 1.5m1.8 1.71c-.57 0-1.1.17-1.55.45a3.55 3.55 0 0 1 2.73 3.45c0 .69-.21 1.33-.55 1.87a2.917 2.917 0 0 0 2.29-2.85c0-1.61-1.31-2.92-2.92-2.92m-2.38 1a2.926 2.926 0 1 0 .011 5.851A2.926 2.926 0 0 0 9.42 4.21m4.25 5.01l-.51.59c2.34.69 2.45 3.61 2.45 3.61h1.28c0-4.71-3.22-4.2-3.22-4.2m-2.1.8l-2.12 2.09l-2.12-2.09C3.12 10.24 3.89 15 3.89 15h11.08c.47-4.98-3.4-4.98-3.4-4.98"
            />
          </svg>
        </aside>
        <h2 className={styles.heading}>CAREER CRAFT'S REPORT ANALYST</h2>
        <br />
        <TextField
          style={{ width: "65%" }}
          label="username"
          variant="outlined"
          type="text"
          //   value={username}
          onChange={(e) => setUsername(e.target.value)}
          required={true}
        />
        <br />
        {/* ****************************************************** */}
        <FormControl variant="outlined" style={{ width: "65%" }}>
          <InputLabel>Password</InputLabel>
          <OutlinedInput
            required={true}
            id={styles.passwordtext}
            type={showPassword ? "text" : "password"}
            onChange={(e) => setPassword(e.target.value)}
            endAdornment={
              <InputAdornment position="end">
                <IconButton
                  aria-label="toggle password visibility"
                  onClick={handleClickShowPassword}
                  onMouseDown={handleMouseDownPassword}
                  edge="end"
                >
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            }
            label="Password"
          />
        </FormControl>

        <br />
        <button id={styles.button} type="submit">
          Login
        </button>
        {/* <Link style={{paddingRight:"69%"}}>Forgot Password</Link> */}
      </form>
    </section>
  );
};

export default Login;
