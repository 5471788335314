import React from "react";
import { PostContextProvider } from "./ContextApi";
import TreeTable from "./TreeTable";

const LandingPage = () => {
  return (
    <>
      <PostContextProvider>
        <TreeTable />
      </PostContextProvider>
    </>
  );
};

export default LandingPage;
