import React, { useState, useEffect } from "react";
import Loader from "../component/loader/Loader";
import { Modal, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AxiosInstance from "../api/AxiosInstance";
import { toast } from "react-toastify";
import "./Academic.css";

const BatchDetailes = (props) => {
  console.log(props, "propsb");

  const [batchDetails, setBatchDetails] = useState([]);
  const [defaultBatch,setDefaultBatch] = useState([]);
  const [load, setload] = useState(false);
  const [pagination, setPagination] = React.useState({
    limit: 25,
    page: 1,
    load: false,
    length: 1,
  });

  console.log(batchDetails, "req");

  useEffect(() => {
    const fetchData = async () => {
      setload(true);
      try {
        await AxiosInstance.get(
          `/hr/studentbatchlist/?student_id=${props.data}`
        )
          .then((response) => {
            console.log(response);
            setBatchDetails(response.data.results);
            setDefaultBatch(response.data);
            setload(false);
            setPagination({
              ...pagination,
              length: response.data.total,
              load: response.data.total > 25 ? false : null,
              page: 1,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch {
        setload(null);
      }
    };
    if (props.data) fetchData();
  }, [props.data]);

  const handleChangePage = async (_, value) => {
    setPagination({
      ...pagination,
      page: value,
      load: true,
    });
    // setLoadRed(true);
    const newPage = value;

    setPagination({ ...pagination, load: true });
    try {
      const { data } = await AxiosInstance.get(
        `/hr/studentbatchlist/?student_id=${props.data}&page=${newPage}`
      );

      setBatchDetails(data.results);
      // setLoadRed(false);

      setPagination({
        ...pagination,
        length: data.total,
        page: value,
        load: false,
      });
    } catch (error) {
      toast.error("something went wrong");
      setPagination({ ...pagination, load: false });
      // setLoadRed(false);
    }
  };

  return (
    <>
    <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "30px", fontWeight: "bold", color: "rgb(48, 143, 174)" ,textAlign:"center"}}
              >
                Batch Details
              </Typography>
              <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
                <Table
                  sx={{
                    borderCollapse: "separate",
                    tableLayout: "fixed",
                  }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead sx={{ backgroundColor: "#495579" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Batch-Id
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Total days
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Attended days
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflow: "auto" }}>
            {pagination.load ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : load === null ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Something went wrong
                </TableCell>
              </TableRow>
            ) : batchDetails.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) :  batchDetails?.map((value, index) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={index}
                            >
                              <TableCell align="center">
                                {value?.batch_name ? value?.batch_name : "----"}
                              </TableCell>
                              <TableCell align="center">
                                {value.total_days ? value.total_days : "----"}
                              </TableCell>
                              <TableCell align="center">
                                {value.attended_days
                                  ? value.attended_days
                                  : "----"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
    <aside
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1%",
        marginBottom: "1%",
      }}
    >
        {pagination.load !== null && (
          <Pagination
            count={Math.ceil(pagination.length / pagination.limit)}
            color="primary"
            defaultPage={1}
            disabled={pagination.load}
            rowsperpage={pagination.limit}
            siblingCount={1}
            showLastButton={Math.ceil(pagination.length / pagination.limit) > 5}
            showFirstButton={
              Math.ceil(pagination.length / pagination.limit) > 5
            }
            page={pagination.page}
            onChange={handleChangePage}
          />
        )}
      </aside>
   </>
  );
};

export default BatchDetailes;
