import React, { useState, useEffect, Fragment, Children } from "react";
import { styled } from "@mui/material/styles";
import {
  Table,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TableBody,
  Box,
  Modal,
  Typography,
  Button,
  Drawer,
  IconButton,
  Grid,
  Autocomplete,
  TextField,
  Input,
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
// import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import MasterTree from "./MasterTree";
import { PostContext } from "./ContextApi";
import StudentCount from "../student_count/StudentCount";
import Styles from "./treetable.module.css";
import RequirementCount from "../requirement_count/RequirementCount";
import { FaTimes } from "react-icons/fa";
import AxiosInstance from "../api/AxiosInstance";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: "center",
  boxShadow: "none",
  color: theme.palette.text.secondary,
}));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80vw",
  bgcolor: "background.paper",
  border: "2px solid #000",
  p: 1,
  height: "auto",
  zIndex: "999",
  borderRadius: "6px",
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "black",
    fontWeight: "bold",
    background: "#9AA2A4",
    letterSpacing: "0px",
    // fontSize:"19px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18.2,
    fontWeight: "bold",
    // padding: "19.7px",
    textAlign: "center",
    // background: "rgba(248, 246, 246, 0.944)",
    // border:"1px solid rgb(88, 85, 85)",
  },
}));

const StyledTableCell1 = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "black",
    fontWeight: "bold",
    background: "#9AA2A4",
    letterSpacing: "0px",
    // fontSize:"19px"
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 18.2,
    fontWeight: "bold",
    // padding: "19.7px",
    textAlign: "center",
    // background: "rgba(248, 246, 246, 0.944)",
    // border:"1px solid rgb(88, 85, 85)",
  },
}));

const TreeTable = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => {
    setSelectedReqTable(null);
    setOpen(false);
  };

  const [selectedData, setSelectedData] = useState("");
  const [selectedLevel, setSelectedLevel] = useState("");
  const [selectedParam, setSelectedParam] = useState("");
  const [selectedReqTable, setSelectedReqTable] = React.useState(null);

  const [toggleOrg, setToggleOrg] = useState(false);

  const {
    expanded,
    organization,
    branches,
    batches,
    currentMonthData,
    setQueryOrg,
    setQueryBatch,
    setQueryBatchCode,
    handleTreereset,
    setMainTableQuery,
    clearData,
  } = React.useContext(PostContext);

  // console.log("expanded", expanded);

  const [columns] = React.useState([
    "Student count",
    "Final Mock taken",
    "Final Mock taken by trainer ",
    "Pre Mock taken",
    "Pre Mock taken by trainer",
    "Requirement given",
    "Got job count",
    "Got job verified count ",
    "Testimonial given",
    "Testimonial verifies count",
    "Mock taken",
    "--------",
    "Final Mock taken",
    "Final Mock taken by trainer",
    "Pre Mock taken",
    "Pre Mock taken by trainer",
    "Requirement given",
    "Got job count",
    "Got job verified",
    "Testimonial given",
    "Testimonial verifies",
    "Mock taken by date",
    "Got job count rejected",
    "Testimonial given rejected",
  ]);

  const [columns1] = React.useState([
    "Requirement count",
    "Total open requirement",
    "closed requirement",
    "--------",
    "Requirement count",
    "Total open requirement",
    "closed requirement",
  ]);

  const [expandedState, setExpandedState] = useState([]);
  const [expandedDistrict, setExpandedDistrict] = useState([]);
  const [expandedCollege, setExpandedCollege] = useState([]);

  // console.log("branches", expandedDistrict);
  // console.log("expandedState", expandedState);
  // console.log("batches", batches);

  const [isTable1Expanded, setIsTable1Expanded] = useState("32%");
  const [isTable2Expanded, setIsTable2Expanded] = useState("32%");

  const toggleTable1 = () => {
    if (isTable1Expanded === "32%") {
      setIsTable1Expanded("70%");
      setIsTable2Expanded("0%");
    } else {
      setIsTable1Expanded("32%");
      setIsTable2Expanded("32%");
    }
  };

  const toggleTable2 = () => {
    if (isTable2Expanded === "32%") {
      setIsTable1Expanded("0%");
      setIsTable2Expanded("70%");
    } else {
      setIsTable1Expanded("32%");
      setIsTable2Expanded("32%");
    }
  };

  // const handleToggleTables = () => {
  //   setIsTable1Expanded((prev) => !prev);
  // };

  useEffect(() => {
    if (expanded.length > 0) {
      let arr1 = [];
      let arr2 = [];
      let arr3 = [];
      let stateArr = expanded.filter(
        (ex) =>
          ex.includes("subject") &&
          !ex.includes("chapter") &&
          !ex.includes("topic")
      );
      if (stateArr.length > 0) {
        stateArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr1.push(xid);
        });
        setExpandedState(arr1);
      } else {
        setExpandedState(arr1);
      }

      // console.log(expanded.filter(
      //   (ex) =>
      //     ex.includes("subject") &&
      //     ex.includes("chapter") &&
      //     !ex.includes("topic")
      // ),"expan")
      let distArr = expanded.filter(
        (ex) =>
          ex.includes("subject") &&
          ex.includes("chapter") &&
          !ex.includes("topic")
      );
      if (distArr.length > 0) {
        distArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("#")[1].replace(/\D/g, "")
          );
          arr2.push(xid);
          // console.log(xid,"e")
        });
        setExpandedDistrict(arr2);
      } else {
        setExpandedDistrict(arr2);
      }
      let collegeArr = expanded.filter(
        (ex) =>
          ex.includes("subject") &&
          ex.includes("chapter") &&
          ex.includes("topic")
      );
      if (collegeArr.length > 0) {
        collegeArr.forEach((e) => {
          let xid = Number.parseInt(
            e.toString().split(",")[0].split("~")[0].replace(/\D/g, "")
          );
          arr3.push(xid);
        });
        setExpandedCollege(arr3);
      } else {
        setExpandedCollege(arr3);
      }
    }
  }, [expanded]);

  // !search filters logic
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [searchButton, setSearchButton] = useState(true);

  const [isSidebarOpen1, setIsSidebarOpen1] = useState(false);
  const [searchButton1, setSearchButton1] = useState(true);

  const handleSearchClick = () => {
    setIsSidebarOpen(true);
  };

  const handleCloseSidebar = () => {
    setIsSidebarOpen(false);
  };

  const handleCloseDrawer = () => {
    setIsSidebarOpen(false);
  };

  //for student search

  const handleSearchClick1 = () => {
    setIsSidebarOpen1(true);
  };

  const handleCloseSidebar1 = () => {
    setIsSidebarOpen1(false);
  };

  const handleCloseDrawer1 = () => {
    setIsSidebarOpen1(false);
  };

  const [branchOptions, setBranchOptions] = useState([]);
  const [selectedBranch, setSelectedBranch] = useState(null);
  const [branchSearch, setBranchSearch] = useState("");

  const [batchOptions, setBatchOptions] = useState([]);
  const [selectedBatch, setSelectedBatch] = useState(null);
  const [batchSearch, setBatchSearch] = useState("");

  const [batchCodeOptions, setBatchCodeOptions] = useState([]);
  const [selectedBatchCode, setSelectedBatchCode] = useState(null);
  const [batchCodeSearch, setBatchCodeSearch] = useState("");
  // console.log("batchCodeOptions", batchOptions);

  // for date filter
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  // console.log(startDate, "startDate");

  const handleDateChangeDate = (event) => {
    setStartDate(event.target.value);
  };

  const handleDateChangeEndDate = (event) => {
    setEndDate(event.target.value);
  };

  useEffect(() => {
    let fetchData = async (e) => {
      //college state api
      if (branchSearch !== "") {
        let colgStateData = await AxiosInstance.get(
          `/report/orgreport/?name=${branchSearch}`
        );
        let finalcolgStateData = colgStateData.data.results;
        console.log(colgStateData, "finalcolgStateData");
        setBranchOptions(finalcolgStateData);
      } else {
        let colgStateData = await AxiosInstance.get(`/report/orgreport/`);
        let finalcolgStateData = colgStateData.data.results;

        setBranchOptions(finalcolgStateData);
      }
    };
    fetchData();
  }, [branchSearch]);

  // console.log("selectedBranch", selectedBranch);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedBranch !== null) {
        if (batchSearch !== "") {
          let colgStateData = await AxiosInstance.get(
            `/report/branchreport/?name=${batchSearch}&organization_id=${selectedBranch.id}`
          );
          let finalcolgStateData = colgStateData.data.results;

          setBatchOptions(finalcolgStateData);
        } else {
          let colgStateData = await AxiosInstance.get(
            `/report/branchreport/?organization_id=${selectedBranch.id}`
          );
          let finalcolgStateData = colgStateData.data.results;
          setBatchOptions(finalcolgStateData);
        }
      }
    };
    fetchData();
  }, [batchSearch, selectedBranch]);

  useEffect(() => {
    let fetchData = async (e) => {
      if (selectedBatch !== null) {
        if (batchCodeSearch !== "") {
          let colgStateData = await AxiosInstance.get(
            `/report/batchreport/?name=${batchCodeSearch}&branch_id=${selectedBatch.id}`
          );
          let finalcolgStateData = colgStateData.data.results;

          setBatchCodeOptions(finalcolgStateData);
        } else {
          let colgStateData = await AxiosInstance.get(
            `/report/batchreport/?branch_id=${selectedBatch.id}`
          );
          let finalcolgStateData = colgStateData.data.results;
          setBatchCodeOptions(finalcolgStateData);
        }
      }
    };
    fetchData();
  }, [batchCodeSearch, selectedBatch]);

  const searchFiltersData = () => {
    if (
      !selectedBranch &&
      !selectedBatch &&
      !selectedBatchCode
    ) {
      toast.warn("please enter a filter to search");
    } else {
      setExpandedState([]);
      setExpandedDistrict([]);
      setExpandedCollege([]);
      clearData();
    }
    if (
      (selectedBranch && selectedBranch.id === 0) ||
      (selectedBatch && selectedBatch.id === 0) ||
      (selectedBatchCode && selectedBatchCode.id === 0) ||
      startDate === null ||
      endDate === null
    ) {
      toast("Select proper data");
      return;
    }

    // const params = [];

    // if (selectedBranch) {
    //   params.push(`id=${selectedBranch.id}`);
    // }

    // if (selectedBatch) {
    //   params.push(`organization_id=${selectedBatch.id}`);
    //   // setQueryBatch(`branch_id=${selectedBatch.ID}`)
    // }
    // if (selectedBatchCode) {
    //   params.push(`branch_id=${selectedBatchCode.id}`);
    // }
    // if(startDate){
    //   params.push(`stdate=${startDate}`)
    // }
    // if(endDate){
    //   params.push(`enddate=${endDate}`)
    // }

    // let queryString = params.length > 0 ? `${params.join("&")}` : "";
    let start = startDate ? `&stdate=${startDate}` : "";
    let end = endDate ? `&enddate=${endDate}` : "";
    let bId = selectedBranch ? `id=${selectedBranch.id}` : "";

    if (selectedBranch || startDate || endDate) {
      setQueryOrg(`${bId}${start}${end}`);
    }

    if (
      (selectedBranch && selectedBranch.id === 0) ||
      (selectedBatch && selectedBatch.id === 0) ||
      (selectedBatchCode && selectedBatchCode.id === 0)
    ) {
      toast("Select proper data");
      return;
    }

    if (selectedBatch) {
      // params1.push(`branch_id=${selectedBatch.ID}`);
      setQueryBatch(
        `organization_id=${selectedBranch.id}&id=${selectedBatch.id}`
      );
    }

    if (selectedBatchCode) {
      // params1.push(`branch_id=${selectedBatch.ID}`);
      setQueryBatchCode(
        `branch_id=${selectedBatch.id}&id=${selectedBatchCode.id}`
      );
    }
  };

  const handleClearSearchTree = () => {
    setBranchSearch("");
    setSelectedBranch(null);
    setBatchSearch("");
    setSelectedBatch(null);
  };

  //! Mobile field validation
  const preventMinus = (e) => {
    if (isNaN(e.key)) {
      e.preventDefault();
    }
  };
  const preventPasteNegative = (e) => {
    const clipboardData = e.clipboardData || window.clipboardData;
    const pastedData = parseFloat(clipboardData.getData("text"));
    if (pastedData < 0) {
      e.preventDefault();
    }
  };

  const handleClearSearch = async () => {
    setStudentSearch({ number: "", email: "", student_name: "" });
  };

  const [studentSearch, setStudentSearch] = useState({
    email: "",
    number: "",
    student_name: "",
  });

  let { number, email, student_name } = studentSearch;
  const Navigate = useNavigate();
  const navigateMainTable = () => {
    if (!number && !email && !student_name) {
      toast.warn("please enter a filter to search");
    } else {
      setMainTableQuery(studentSearch);
      Navigate("/table", {
        state: {
          isNavigation: true,
          searchNumber: studentSearch.number,
          searchEmail: studentSearch.email,
          searchstudent_name: studentSearch.student_name,
        },
      });
    }
  };

  const handlechange = async (e) => {
    let { name, value } = e.target;
    setStudentSearch({ ...studentSearch, [name]: value });
    console.log(studentSearch, "student");
  };
  return (
    <>
      <div style={{ maxHeight: "90vh", overflowY: "auto" }}>
        {/* search filters */}
        <div style={{ display: "flex" }}>
          <Box
            sx={{
              height: "6vh",
              width: "100%",
              // background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: "2px solid #9BA4B5",
            }}
          >
            <Box sx={{ width: 300 }}>
              <Drawer
                anchor="right"
                open={isSidebarOpen}
                onClose={handleCloseSidebar}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: 1,
                    paddingTop: 1,
                  }}
                >
                  <h2
                    style={{
                      width: "100%",
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: "#40679E",
                    }}
                  >
                    Search List
                  </h2>
                  <IconButton onClick={handleCloseDrawer}>
                    <FaTimes style={{ color: "red" }} />
                  </IconButton>
                </Box>
                <Box sx={{ width: 350 }}>
                  <Grid
                    item
                    container
                    sm={12}
                    spacing={2}
                    style={{ padding: "10px 10px" }}
                  >
                    <Grid item sm={12} style={{ marginTop: "-2%" }}>
                      <p style={{ fontWeight: "bold" }}>Search Branch Name</p>
                      <Item
                        onChange={(e) => {
                          setBranchSearch(e.target.value);
                        }}
                      >
                        <Autocomplete
                          style={{ marginLeft: "-2%" }}
                          required
                          size="small"
                          options={branchOptions}
                          value={selectedBranch}
                          name="branch_name"
                          onChange={(event, newColgState) => {
                            setSelectedBranch(newColgState);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Branch Name"
                              variant="outlined"
                            />
                          )}
                        />
                      </Item>
                    </Grid>
                    <Grid item sm={12} style={{ marginTop: "-2%" }}>
                      <p style={{ fontWeight: "bold" }}>Search Batch</p>
                      <Item
                        onChange={(e) => {
                          setBatchSearch(e.target.value);
                        }}
                      >
                        <Autocomplete
                          style={{ marginLeft: "-2%" }}
                          required
                          size="small"
                          options={batchOptions}
                          value={selectedBatch}
                          name="batch"
                          onChange={(event, newColgState) => {
                            setSelectedBatch(newColgState);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Batch"
                              variant="outlined"
                            />
                          )}
                        />
                      </Item>
                    </Grid>
                    <Grid item sm={12} style={{ marginTop: "-2%" }}>
                      <p style={{ fontWeight: "bold" }}>Search Batch Code</p>
                      <Item
                        onChange={(e) => {
                          setBatchCodeSearch(e.target.value);
                        }}
                      >
                        <Autocomplete
                          style={{ marginLeft: "-2%" }}
                          required
                          size="small"
                          options={batchCodeOptions}
                          value={selectedBatchCode}
                          name="batch_code"
                          onChange={(event, newColgState) => {
                            setSelectedBatchCode(newColgState);
                          }}
                          getOptionLabel={(option) => option.name}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label="Search Batch code"
                              variant="outlined"
                            />
                          )}
                        />
                      </Item>
                    </Grid>
                    <Grid item sm={12} style={{ marginTop: "-2%" }}>
                      <p style={{ fontWeight: "bold" }}>Start Date</p>
                      <Input
                        type="date"
                        id="date"
                        name="date"
                        value={startDate}
                        onChange={handleDateChangeDate}
                      />
                    </Grid>
                    <Grid item sm={12} style={{ marginTop: "-2%" }}>
                      <p style={{ fontWeight: "bold" }}>End Date</p>
                      <Input
                        type="date"
                        id="date"
                        name="date"
                        value={endDate}
                        onChange={handleDateChangeEndDate}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={12}
                    spacing={4}
                    style={{ padding: "5px" }}
                  >
                    <Grid item sm={4}>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#C6DEFF",
                          color: "black",
                          border: "2px solid gray",
                        }}
                        fullWidth
                        // onClick={() => {
                        //   if (
                        //     searchEmail ||
                        //     searchPhone ||
                        //     selectedBranch
                        //   ) {
                        //     handleSearch();
                        //     setIsSearched(true);
                        //   } else {
                        //     toast.warn("please enter a filter to search");
                        //   }
                        // }}
                        onClick={searchFiltersData}
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item sm={4}>
                      <Button
                        style={{
                          backgroundColor: "lightgray",
                          color: "black",
                          border: "2px solid gray",
                        }}
                        // color="primary"
                        variant="contained"
                        // style={btnstyle1}
                        fullWidth
                        onClick={handleClearSearchTree}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Drawer>
            </Box>
            <span style={{ display: "flex", marginLeft: "140%" }}>
              <p
                style={{
                  width: "200px",
                  display: "inline-block",
                  marginRight: "20px",
                }}
              >
                {searchButton ? (
                  <Button
                    style={{ backgroundColor: "rgb(48 143 174 / 77%)" }}
                    // color="primary"
                    variant="contained"
                    onClick={handleSearchClick}
                    // disabled={load}
                  >
                    Tree Search
                  </Button>
                ) : undefined}
              </p>
              <p>
                <Button
                  style={{ backgroundColor: "gray" }}
                  variant="contained"
                  // style={btnstyle1}
                  onClick={handleTreereset}
                >
                  Reset
                </Button>
              </p>
            </span>
          </Box>
          {/* search for student detilas */}
          <Box
            sx={{
              height: "6vh",
              width: "100%",
              // background: "#DDE6ED",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderBottom: "2px solid #9BA4B5",
            }}
          >
            <Box sx={{ width: 300 }}>
              <Drawer
                anchor="right"
                open={isSidebarOpen1}
                onClose={handleCloseSidebar1}
              >
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-end",
                    paddingRight: 1,
                    paddingTop: 1,
                  }}
                >
                  <h2
                    style={{
                      width: "100%",
                      marginTop: "2%",
                      marginLeft: "2%",
                      color: "#40679E",
                    }}
                  >
                    Search List
                  </h2>
                  <IconButton onClick={handleCloseDrawer1}>
                    <FaTimes style={{ color: "red" }} />
                  </IconButton>
                </Box>
                <Box sx={{ width: 320 }}>
                  <Grid
                    item
                    container
                    sm={12}
                    spacing={2}
                    style={{ padding: "10px 10px" }}
                  >
                    <Grid item sm={12}>
                      <TextField
                        size="small"
                        sx={{ minWidth: 210 }}
                        fullWidth
                        name="student_name"
                        label="Search By Name"
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 10, maxLength: 10 }}
                        value={student_name}
                        onChange={(e) => handlechange(e)}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        size="small"
                        sx={{ minWidth: 210 }}
                        fullWidth
                        name="number"
                        label="Search By Number"
                        onKeyPress={preventMinus}
                        onPaste={preventPasteNegative}
                        inputProps={{ minLength: 10, maxLength: 10 }}
                        value={number}
                        onChange={(e) => handlechange(e)}
                      />
                    </Grid>
                    <Grid item sm={12}>
                      <TextField
                        size="small"
                        sx={{ minWidth: 210 }}
                        fullWidth
                        name="email"
                        label="Search By Email"
                        value={email}
                        onChange={(e) => handlechange(e)}
                      />
                    </Grid>
                  </Grid>
                  <Grid
                    item
                    container
                    sm={12}
                    spacing={4}
                    style={{ padding: "5px" }}
                  >
                    <Grid item sm={4}>
                      <Button
                        type="button"
                        color="primary"
                        variant="contained"
                        style={{
                          backgroundColor: "#C6DEFF",
                          color: "black",
                          border: "2px solid gray",
                        }}
                        fullWidth
                        // onClick={() => {
                        //   if (
                        //     email ||
                        //     phone
                        //   ) {
                        //     setIsSearched(true);
                        //   } else {
                        //     toast.warn("please enter a filter to search");
                        //   }
                        // }}
                        onClick={navigateMainTable}
                      >
                        Search
                      </Button>
                    </Grid>
                    <Grid item sm={4}>
                      <Button
                        style={{
                          backgroundColor: "lightgray",
                          color: "black",
                          border: "2px solid gray",
                        }}
                        // color="primary"
                        variant="contained"
                        // style={btnstyle1}
                        fullWidth
                        onClick={handleClearSearch}
                      >
                        Clear
                      </Button>
                    </Grid>
                  </Grid>
                </Box>
              </Drawer>
            </Box>
            <p
              style={{
                // width: "200px",
                display: "inline-block",
                marginRight: "20px",
              }}
            >
              {searchButton1 ? (
                <Button
                  style={{ backgroundColor: "rgb(48 143 174 / 77%)" }}
                  // color="primary"
                  variant="contained"
                  onClick={handleSearchClick1}
                  // disabled={load}
                >
                  Student Search
                </Button>
              ) : undefined}
            </p>
            {/* <p>
                      {isSearched ? (
                        <Button
                          style={{ backgroundColor: "gray" }}
                          variant="contained"
                          // style={btnstyle1}
                          onClick={handleClearSearch}
                        >
                          Reset
                        </Button>
                      ) : undefined}
                    </p> */}
          </Box>
        </div>
        {/* search filters end */}
        <div id={Styles.container}>
          <section
            id="treeStructure"
            style={{ width: "100%", minHeight: "100vh", overflowY: "auto" }}
          >
            {/* modal for student view */}

            <Modal open={open} onClose={handleClose}>
              <Box sx={style} style={{ position: "relative" }}>
                <button
                  onClick={handleClose}
                  style={{
                    position: "absolute",
                    right: "0px",
                    top: "0px",
                    background: "transparent",
                    border: "none",
                  }}
                >
                  <svg width="27.81px" height="30px" viewBox="0 0 1216 1312">
                    <path
                      fill="red"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
                </button>
                {selectedData === "" ? (
                  "Loading..."
                ) : selectedReqTable === null ? (
                  <StudentCount
                    appointment_idx={selectedData}
                    quizAttendedLevel={selectedLevel}
                    selectedParam={selectedParam}
                  />
                ) : (
                  <RequirementCount
                    appointment_idx={selectedData}
                    quizAttendedLevel={selectedLevel}
                    selectedParam={selectedParam}
                  />
                )}
              </Box>
            </Modal>
            <article
              style={{
                width: "100%",
                display: "flex",
                minHeight: "100vh",
                position: "relative",
                gap: "10px",

                // top: "7vh",
              }}
            >
              <aside
                style={{
                  width: "40%",
                  height: "auto",
                  marginTop: "2.5%",
                }}
                className="treeBlock"
              >
                <Box
                  onClick={() => {
                    setToggleOrg(!toggleOrg);
                  }}
                  style={{
                    // backgroundColor:
                    //   role === "1"
                    //     ? "#e2eafc"
                    //     : role === "5"
                    //     ? "#F5EDDC"
                    //     : "#e2eafc",
                    // padding: "7px",
                    marginBottom: "5%",
                  }}
                >
                  &nbsp; &nbsp; &nbsp;
                  <svg width="35px" height="35px" viewBox="0 0 24 24">
                    <path
                      fill="black"
                      fillRule="evenodd"
                      d="M9 1H1v8h8V6h2v14h4v3h8v-8h-8v3h-2V6h2v3h8V1h-8v3H9zm12 2h-4v4h4zm-4 14h4v4h-4z"
                      clipRule="evenodd"
                    ></path>
                  </svg>
                  <span
                    style={{
                      fontSize: "36px",
                      fontWeight: "bold",
                      color: "rgb(48, 143, 174)",
                    }}
                  >
                    Organisation
                  </span>
                </Box>
                <div>{toggleOrg ? <MasterTree /> : null}</div>
              </aside>
              {/* table1 */}
              <aside
                style={{
                  width: isTable1Expanded,
                  // isTable1Expanded.boolen1 === null
                  //   ? "35%"
                  //   : isTable1Expanded.boolen1 === true
                  //   ? "70%"
                  //   : "0%",
                  borderRight: "1px solid lightgray",
                  borderLeft: "1px solid lightgray",
                }}
                className="tableBlock"
              >
                <div
                  className="table container"
                  style={{
                    transition: "300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    boxShadow:
                      "inset 0px 2px 1px -1px rgba(0,0,0,0.2), inset 2px -1px 20px 16px rgba(0,0,0,0.14), inset -16px -11px 9px 6px rgba(0,0,0,0.12)",
                    width: "100%",
                    overflowX: "auto",
                    minHeight: "100%",
                    whiteSpace: "nowrap",
                    border: "1px solid gray",
                    position: "relative",
                  }}
                >
                  {isTable1Expanded !== "0%" && (
                    <button
                      variant="outlined"
                      onClick={toggleTable1}
                      id={Styles.toggle1}
                    >
                      {isTable1Expanded === "32%" ? "Expand" : "Shrink"}
                    </button>
                  )}
                  {isTable1Expanded && (
                    <TableContainer
                      component={Paper}
                      style={{ minHeight: "100%" }}
                    >
                      {/* <div style={{ maxHeight: '90vh', overflowY: 'auto'}}> */}
                      <Table
                        sx={{ minWidth: 700 }}
                        stickyHeader
                        aria-label="sticky table"
                        className="table"
                      >
                        {/* <div  style={{border:"2px solid red",position:"fixed"}}> */}
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: "25px",
                                fontWeight: "600",
                                color: "rgb(48, 143, 174)",
                                padding: "1px",
                                borderBottom: "0px",
                                // gap: "2px",
                              }}
                            >
                              Student Count
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={3}
                              align="center"
                              style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                color: "rgb(48, 143, 174)",
                                padding: "0px",
                                // gap: "2px",
                              }}
                            >
                              Over all Count
                            </TableCell>
                            <TableCell
                              colSpan={20}
                              align="center"
                              style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                color: "rgb(48, 143, 174)",
                                padding: "0px",
                                // gap: "2px",
                              }}
                            >
                              Summary
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            {columns.map((column, index) => (
                              <StyledTableCell
                                key={index}
                                style={{
                                  background: index === 11 ? "lightgray" : "",
                                  width: index === 11 ? "60px" : "",
                                  color: index === 11 ? "lightgray" : "",
                                  // visibility: index === 11 ? "hidden" : "",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  id="innerChild"
                                >
                                  {column}
                                </Box>
                              </StyledTableCell>
                            ))}
                          </TableRow>
                        </TableHead>
                        {/* </div> */}
                        {/* //!   organisation name starts  */}

                        {toggleOrg ? (
                          organization.data?.length > 0 ? (
                            <TableBody>
                              {organization.data?.map((coun, ind) => {
                                return (
                                  <Fragment key={ind + 1}>
                                    <TableRow key={ind + 1}>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.st_count}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam("");
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.Final_Mock_taken}
                                        {""}

                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam("final_mock=true");
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .Final_Mock_taken_by_trainer
                                        }
                                        {""}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              "final_by_trainer=true"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.Pre_Mock_taken}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              "final_mock=false"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .Pre_Mock_taken_by_trainer
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              "pre_by_trainer=true"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>

                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.Req_given}
                                        {""}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam("req_given=true");
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.got_job_count}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam("got_job=true");
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .got_job_count_verified
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              "got_job=true&&verify=1"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.testimonial_given}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              "testimonial_created=true"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .testimonial_given_verified
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              "testi_verified=true"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.Mock_taken}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam("mock_given=true");
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        style={{ backgroundColor: "lightgray" }}
                                      ></StyledTableCell>
                                      {/* the student overall count ends */}
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .Final_Mock_taken_by_date
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `final_mock=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .Final_Mock_taken_by_trainer_by_date
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `final_by_trainer=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .Pre_Mock_taken_by_date
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `pre_mock=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .Pre_Mock_taken_by_trainer_by_date
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `pre_by_trainer=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.Req_given_by_date}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `req_given=true&streq_created_after=${currentMonthData.firstDay}&streq_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .got_job_count_by_date
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `got_job=true&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .got_job_count_by_date_verified
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `verify=1&gj_created_after=${currentMonthData.lastDay}&gj_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .testimonial_given_by_date
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `testimonial_created=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .testimonial_given_by_date_verified
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `testi_verified=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {coun.student_count.Mock_taken_by_date}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `mock_given=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .got_job_count_by_date_Rejected
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `verify=2&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                      <StyledTableCell
                                        className={Styles.orgStyle}
                                      >
                                        {
                                          coun.student_count
                                            .testimonial_given_by_date_Rejected
                                        }
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedParam(
                                              `testi_rejected=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell>
                                    </TableRow>
                                    {/*//! organisation ends */}

                                    {/*  //!branches starts */}
                                    {expanded.length > 0
                                      ? branches && (
                                          <>
                                            {Object.fromEntries(
                                              Object.entries(branches).filter(
                                                ([key]) =>
                                                  expandedState.includes(
                                                    Number(key)
                                                  )
                                              )
                                            )[coun.id]?.data === null ? (
                                              <TableRow>
                                                <StyledTableCell
                                                  colSpan={3}
                                                  // className={Styles.batchStyle}
                                                  style={{
                                                    background: "#fff",
                                                    textDecoration: "none",
                                                    padding:"14px",
                                                    
                                                  }}
                                                >
                                                  No Data
                                                </StyledTableCell>
                                              </TableRow>
                                            ) : (
                                              <>
                                                {Object.fromEntries(
                                                  Object.entries(
                                                    branches
                                                  ).filter(([key]) =>
                                                    expandedState.includes(
                                                      Number(key)
                                                    )
                                                  )
                                                )[coun.id]?.data?.map(
                                                  (state, indStat) => {
                                                    console.log(state, "state");
                                                    return (
                                                      <Fragment
                                                        key={indStat + 1}
                                                      >
                                                        <TableRow>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            {/* {state.student_count.st_count} */}
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  ""
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .st_count
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "final_mock=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Final_Mock_taken
                                                              }
                                                              {""}
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "final_by_trainer=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Final_Mock_taken_by_trainer
                                                              }
                                                              {""}
                                                            </span>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Pre_Mock_taken
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "pre_by_trainer=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Pre_Mock_taken_by_trainer
                                                              }
                                                            </span>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "req_given=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Req_given
                                                              }
                                                              {""}
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "got_job=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .got_job_count
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "got_job=true&&verify=1"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .got_job_count_verified
                                                              }
                                                            </span>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "testimonial_created=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .testimonial_given
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "testi_verified=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .testimonial_given_verified
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  "mock_given=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Mock_taken
                                                              }
                                                            </span>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            style={{
                                                              backgroundColor:
                                                                "lightgray",
                                                            }}
                                                          ></StyledTableCell>
                                                          {/* the student overall count ends */}
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `final_mock=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Final_Mock_taken_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `final_by_trainer=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Final_Mock_taken_by_trainer_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `pre_mock=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Pre_Mock_taken_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `pre_by_trainer=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Pre_Mock_taken_by_trainer_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `req_given=true&streq_created_after=${currentMonthData.firstDay}&streq_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Req_given_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>

                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `got_job=true&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .got_job_count_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `verify=1&gj_created_after=${currentMonthData.lastDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .got_job_count_by_date_verified
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `testimonial_created=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .testimonial_given_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `testi_verified=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .testimonial_given_by_date_verified
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `mock_given=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .Mock_taken_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `verify=2&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .got_job_count_by_date_Rejected
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                          <StyledTableCell
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedParam(
                                                                  `testi_rejected=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state
                                                                  .student_count
                                                                  .testimonial_given_by_date_Rejected
                                                              }
                                                            </span>
                                                          </StyledTableCell>
                                                        </TableRow>
                                                        {/* //! BRANCHES ENDS  */}

                                                        {/* //! Batches starts */}

                                                        {expanded.length > 0
                                                          ? batches && (
                                                              <>
                                                                {Object.fromEntries(
                                                                  Object.entries(
                                                                    batches
                                                                  ).filter(
                                                                    ([key]) =>
                                                                      expandedDistrict.includes(
                                                                        Number(
                                                                          key
                                                                        )
                                                                      )
                                                                  )
                                                                )[state.id]
                                                                  ?.data ===
                                                                null ? (
                                                                  <TableRow>
                                                                    <StyledTableCell
                                                                      colSpan={
                                                                        3
                                                                      }
                                                                      // className={
                                                                      //   Styles.batchStyle
                                                                      // }
                                                                      style={{
                                                                        background:
                                                                          "#fff",
                                                                        textDecoration:
                                                                          "none",
                                                                          padding:"14px"
                                                                      }}
                                                                    >
                                                                      No Data
                                                                    </StyledTableCell>
                                                                  </TableRow>
                                                                ) : (
                                                                  <>
                                                                    {Object.fromEntries(
                                                                      Object.entries(
                                                                        batches
                                                                      ).filter(
                                                                        ([
                                                                          key,
                                                                        ]) =>
                                                                          expandedDistrict.includes(
                                                                            Number(
                                                                              key
                                                                            )
                                                                          )
                                                                      )
                                                                    )[
                                                                      state.id
                                                                    ]?.data?.map(
                                                                      (
                                                                        batchcode,
                                                                        indStat
                                                                      ) => {
                                                                        return (
                                                                          <Fragment
                                                                            key={
                                                                              indStat +
                                                                              1
                                                                            }
                                                                          >
                                                                            <TableRow>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      ""
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .st_count
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "final_mock=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Final_Mock_taken
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "final_by_trainer=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Final_Mock_taken_by_trainer
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>

                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "final_mock=false"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Pre_Mock_taken
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "pre_by_trainer=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Pre_Mock_taken_by_trainer
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "req_given=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Req_given
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "got_job=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .got_job_count
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "got_job=true&&verify=1"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .got_job_count_verified
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "testimonial_created=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .testimonial_given
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "testi_verified=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .testimonial_given_verified
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "mock_given=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Mock_taken
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>

                                                                              <StyledTableCell
                                                                                style={{
                                                                                  backgroundColor:
                                                                                    "lightgray",
                                                                                }}
                                                                              ></StyledTableCell>
                                                                              {/* the student overall count ends */}
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `final_mock=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Final_Mock_taken_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `final_by_trainer=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Final_Mock_taken_by_trainer_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `pre_mock=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Pre_Mock_taken_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `pre_by_trainer=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Pre_Mock_taken_by_trainer_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `req_given=true&streq_created_after=${currentMonthData.firstDay}&streq_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Req_given_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>

                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `got_job=true&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .got_job_count_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `verify=1&gj_created_after=${currentMonthData.lastDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .got_job_count_by_date_verified
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `testimonial_created=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .testimonial_given_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "1"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `testi_verified=true&testi_created_after=${currentMonthData.firstDay}&testi_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .testimonial_given_by_date_verified
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "1"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `mock_given=true&mock_created_after=${currentMonthData.firstDay}&mock_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .Mock_taken_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "1"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `verify=2&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .got_job_count_by_date_Rejected
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                              <StyledTableCell
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "1"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `verify=2&gj_created_after=${currentMonthData.firstDay}&gj_created_before=${currentMonthData.lastDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .student_count
                                                                                      .testimonial_given_by_date_Rejected
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell>
                                                                            </TableRow>
                                                                          </Fragment>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )
                                                          : null}
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          </>
                                        )
                                      : null}
                                  </Fragment>
                                );
                              })}
                              {/* //!batches ends */}
                            </TableBody>
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ color: "red" }}>
                                  Loading Table Data...
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )
                        ) : null}
                      </Table>
                      {/* </div> */}
                    </TableContainer>
                  )}
                </div>
              </aside>

              {/* //! second table */}
              <aside
                style={{
                  width: isTable2Expanded,
                  // isTable1Expanded.boolen2 === null
                  //   ? "35%"
                  //   : isTable1Expanded.boolen2 === true
                  //   ? "70%"
                  //   : "0%",
                  borderRight: "1px solid lightgray",
                  borderLeft: "1px solid lightgray",
                }}
                className="tableBlock"
              >
                <div
                  style={{
                    transition: "300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms",
                    boxShadow:
                      "inset 0px 2px 1px -1px rgba(0,0,0,0.2), inset 2px -1px 20px 16px rgba(0,0,0,0.14), inset -16px -11px 9px 6px rgba(0,0,0,0.12)",
                    width: "100%",
                    overflowX: "auto",
                    minHeight: "100%",
                    whiteSpace: "nowrap",
                    border: "1px solid gray",
                    position: "relative",
                    // position:"sticky"
                  }}
                >
                  {isTable2Expanded !== "0%" && (
                    <button
                      variant="outlined"
                      onClick={toggleTable2}
                      id={Styles.toggle}
                    >
                      {isTable2Expanded === "32%" ? "Expand" : "Shrink"}
                    </button>
                  )}
                  {isTable2Expanded && (
                    <TableContainer
                      component={Paper}
                      style={{ minHeight: "100%" }}
                    >
                      {/* <div style={{ maxHeight: '90vh', overflowY: 'auto'}}> */}
                      <Table
                        className="table"
                        sx={{ minWidth: 700 }}
                        aria-label="sticky table"
                        stickyHeader
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell
                              style={{
                                fontSize: "25px",
                                fontWeight: "600",
                                color: "rgb(48, 143, 174)",
                                padding: "1px",
                                borderBottom: "0px",
                              }}
                            >
                              Requirement Count
                            </TableCell>
                          </TableRow>
                          <TableRow>
                            <TableCell
                              colSpan={4}
                              align="center"
                              style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                color: "rgb(48, 143, 174)",
                                padding: "0px",
                                // gap: "2px",
                              }}
                            >
                              Over all Count
                            </TableCell>
                            <TableCell
                              colSpan={3}
                              align="center"
                              style={{
                                fontSize: "22px",
                                fontWeight: "600",
                                color: "rgb(48, 143, 174)",
                                padding: "0px",
                                // gap: "2px",
                              }}
                            >
                              Summary
                            </TableCell>
                          </TableRow>

                          <TableRow>
                            {columns1.map((column, index) => (
                              <StyledTableCell1
                                key={index}
                                style={{
                                  background: index === 3 ? "lightgray" : "",
                                  width: index === 3 ? "60px" : "",
                                  color: index === 3 ? "lightgray" : "",
                                  // visibility: index === 3 ? "hidden" : "",
                                }}
                              >
                                <Box
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                  }}
                                  id="innerChild"
                                >
                                  {column}
                                </Box>
                              </StyledTableCell1>
                            ))}
                          </TableRow>
                        </TableHead>
                        {/* //!   organisation name starts  */}
                        {toggleOrg ? (
                          organization.data?.length > 0 ? (
                            <TableBody>
                              {organization.data?.map((coun, ind) => {
                                return (
                                  <Fragment key={ind + 1}>
                                    <TableRow key={ind + 1}>
                                      <StyledTableCell1
                                        className={Styles.orgStyle}
                                      >
                                        {coun.req_count.req_count}

                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedReqTable("req");
                                            setSelectedParam("");
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell1>
                                      <StyledTableCell1
                                        className={Styles.orgStyle}
                                      >
                                        {coun.req_count.req_open}
                                        {""}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedReqTable("req");
                                            setSelectedParam(
                                              "status__close_req=false"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell1>
                                      <StyledTableCell1
                                        className={Styles.orgStyle}
                                      >
                                        {coun.req_count.req_closed}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedReqTable("req");
                                            setSelectedParam(
                                              "status__close_req=true"
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell1>
                                      <StyledTableCell
                                        style={{ backgroundColor: "lightgray" }}
                                      ></StyledTableCell>
                                      {/* requirement over all ends */}
                                      <StyledTableCell1
                                        className={Styles.orgStyle}
                                      >
                                        {coun.req_count.req_count_by_date}
                                        {""}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedReqTable("req");
                                            setSelectedParam(
                                              `created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell1>
                                      <StyledTableCell1
                                        className={Styles.orgStyle}
                                      >
                                        {coun.req_count.req_open_by_date}
                                        {""}
                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedReqTable("req");
                                            setSelectedParam(
                                              `status__close_req=false&created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell1>
                                      <StyledTableCell1
                                        className={Styles.orgStyle}
                                      >
                                        {coun.req_count.req_closed_by_date}

                                        <span
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedData(coun.id);
                                            setSelectedLevel("1");
                                            setOpen(true);
                                            setSelectedReqTable("req");
                                            setSelectedParam(
                                              `status__close_req=true&created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                            );
                                          }}
                                        >
                                          <svg
                                            width="1em"
                                            height="1em"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="black"
                                              d="M12 9a3 3 0 0 0-3 3a3 3 0 0 0 3 3a3 3 0 0 0 3-3a3 3 0 0 0-3-3m0 8a5 5 0 0 1-5-5a5 5 0 0 1 5-5a5 5 0 0 1 5 5a5 5 0 0 1-5 5m0-12.5C7 4.5 2.73 7.61 1 12c1.73 4.39 6 7.5 11 7.5s9.27-3.11 11-7.5c-1.73-4.39-6-7.5-11-7.5"
                                            ></path>
                                          </svg>
                                        </span>
                                      </StyledTableCell1>
                                    </TableRow>
                                    {/*//! organisation ends */}

                                    {/*  //!branches starts */}
                                    {expanded.length > 0
                                      ? branches && (
                                          <>
                                            {Object.fromEntries(
                                              Object.entries(branches).filter(
                                                ([key]) =>
                                                  expandedState.includes(
                                                    Number(key)
                                                  )
                                              )
                                            )[coun.id]?.data === null ? (
                                              <TableRow>
                                                <StyledTableCell
                                                  colSpan={3}
                                                  // className={Styles.batchStyle}
                                                  style={{
                                                    background: "#fff",
                                                    textDecoration: "none",
                                                    padding:"14px"
                                                  }}
                                                >
                                                  No Data
                                                </StyledTableCell>
                                              </TableRow>
                                            ) : (
                                              <>
                                                {Object.fromEntries(
                                                  Object.entries(
                                                    branches
                                                  ).filter(([key]) =>
                                                    expandedState.includes(
                                                      Number(key)
                                                    )
                                                  )
                                                )[coun.id]?.data?.map(
                                                  (state, indStat) => {
                                                    return (
                                                      <Fragment
                                                        key={indStat + 1}
                                                      >
                                                        <TableRow>
                                                          <StyledTableCell1
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "1"
                                                                );
                                                                setOpen(true);
                                                                setSelectedReqTable(
                                                                  "req"
                                                                );
                                                                setSelectedParam(
                                                                  ""
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state.req_count
                                                                  .req_count
                                                              }
                                                            </span>
                                                          </StyledTableCell1>
                                                          <StyledTableCell1
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "1"
                                                                );
                                                                setOpen(true);
                                                                setSelectedReqTable(
                                                                  "req"
                                                                );
                                                                setSelectedParam(
                                                                  "status__close_req=false"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state.req_count
                                                                  .req_open
                                                              }
                                                            </span>
                                                          </StyledTableCell1>
                                                          <StyledTableCell1
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedReqTable(
                                                                  "req"
                                                                );
                                                                setSelectedParam(
                                                                  "status__close_req=true"
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state.req_count
                                                                  .req_closed
                                                              }
                                                            </span>
                                                          </StyledTableCell1>
                                                          <StyledTableCell1
                                                            style={{
                                                              backgroundColor:
                                                                "lightgray",
                                                            }}
                                                          ></StyledTableCell1>
                                                          {/* requirement over all ends */}
                                                          <StyledTableCell1
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedReqTable(
                                                                  "req"
                                                                );
                                                                setSelectedParam(
                                                                  `created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state.req_count
                                                                  .req_count_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell1>
                                                          <StyledTableCell1
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedReqTable(
                                                                  "req"
                                                                );
                                                                setSelectedParam(
                                                                  `status__close_req=false&created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state.req_count
                                                                  .req_open_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell1>
                                                          <StyledTableCell1
                                                            className={
                                                              Styles.branchStyle
                                                            }
                                                          >
                                                            <span
                                                              style={{
                                                                cursor:
                                                                  "pointer",
                                                              }}
                                                              onClick={() => {
                                                                setSelectedData(
                                                                  state.id
                                                                );
                                                                setSelectedLevel(
                                                                  "2"
                                                                );
                                                                setOpen(true);
                                                                setSelectedReqTable(
                                                                  "req"
                                                                );
                                                                setSelectedParam(
                                                                  `status__close_req=true&created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                                                );
                                                              }}
                                                            >
                                                              {
                                                                state.req_count
                                                                  .req_closed_by_date
                                                              }
                                                            </span>
                                                          </StyledTableCell1>
                                                        </TableRow>
                                                        {/* //! BRANCHES ENDS */}
                                                        {/* //! Batches starts */}
                                                        {expanded.length > 0
                                                          ? batches && (
                                                              <>
                                                                {Object.fromEntries(
                                                                  Object.entries(
                                                                    batches
                                                                  ).filter(
                                                                    ([key]) =>
                                                                      expandedDistrict.includes(
                                                                        Number(
                                                                          key
                                                                        )
                                                                      )
                                                                  )
                                                                )[state.id]
                                                                  ?.data ===
                                                                null ? (
                                                                  <TableRow>
                                                                    <StyledTableCell
                                                                      colSpan={
                                                                        3
                                                                      }
                                                                      // className={
                                                                      //   Styles.batchStyle
                                                                      // }
                                                                      style={{
                                                                        background:
                                                                          "#fff",
                                                                        textDecoration:
                                                                          "none",
                                                                          padding:"14px"
                                                                      }}
                                                                    >
                                                                      No Data
                                                                    </StyledTableCell>
                                                                  </TableRow>
                                                                ) : (
                                                                  <>
                                                                    {Object.fromEntries(
                                                                      Object.entries(
                                                                        batches
                                                                      ).filter(
                                                                        ([
                                                                          key,
                                                                        ]) =>
                                                                          expandedDistrict.includes(
                                                                            Number(
                                                                              key
                                                                            )
                                                                          )
                                                                      )
                                                                    )[
                                                                      state.id
                                                                    ]?.data?.map(
                                                                      (
                                                                        batchcode,
                                                                        indStat
                                                                      ) => {
                                                                        return (
                                                                          <Fragment
                                                                            key={
                                                                              indStat +
                                                                              1
                                                                            }
                                                                          >
                                                                            <TableRow>
                                                                              <StyledTableCell1
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedReqTable(
                                                                                      "req"
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      ""
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .req_count
                                                                                      .req_count
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell1>
                                                                              <StyledTableCell1
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedReqTable(
                                                                                      "req"
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "status__close_req=false"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .req_count
                                                                                      .req_open
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell1>
                                                                              <StyledTableCell1
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedReqTable(
                                                                                      "req"
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      "status__close_req=true"
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .req_count
                                                                                      .req_closed
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell1>
                                                                              <StyledTableCell1
                                                                                style={{
                                                                                  backgroundColor:
                                                                                    "lightgray",
                                                                                }}
                                                                              ></StyledTableCell1>
                                                                              {/* requirement over all ends */}
                                                                              <StyledTableCell1
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedReqTable(
                                                                                      "req"
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .req_count
                                                                                      .req_count_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell1>
                                                                              <StyledTableCell1
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedReqTable(
                                                                                      "req"
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `status__close_req=false&created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .req_count
                                                                                      .req_open_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell1>
                                                                              <StyledTableCell1
                                                                                className={
                                                                                  Styles.batchStyle
                                                                                }
                                                                              >
                                                                                <span
                                                                                  style={{
                                                                                    cursor:
                                                                                      "pointer",
                                                                                  }}
                                                                                  onClick={() => {
                                                                                    setSelectedData(
                                                                                      batchcode.id
                                                                                    );
                                                                                    setSelectedLevel(
                                                                                      "3"
                                                                                    );
                                                                                    setOpen(
                                                                                      true
                                                                                    );
                                                                                    setSelectedReqTable(
                                                                                      "req"
                                                                                    );
                                                                                    setSelectedParam(
                                                                                      `status__close_req=true&created_before=${currentMonthData.lastDay}&created_after=${currentMonthData.firstDay}`
                                                                                    );
                                                                                  }}
                                                                                >
                                                                                  {
                                                                                    batchcode
                                                                                      .req_count
                                                                                      .req_closed_by_date
                                                                                  }
                                                                                </span>
                                                                              </StyledTableCell1>
                                                                            </TableRow>
                                                                          </Fragment>
                                                                        );
                                                                      }
                                                                    )}
                                                                  </>
                                                                )}
                                                              </>
                                                            )
                                                          : null}
                                                      </Fragment>
                                                    );
                                                  }
                                                )}
                                              </>
                                            )}
                                          </>
                                        )
                                      : null}
                                  </Fragment>
                                );
                              })}
                              {/* //!batches ends */}
                            </TableBody>
                          ) : (
                            <TableBody>
                              <TableRow>
                                <TableCell style={{ color: "red" }}>
                                  Loading Table Data...
                                </TableCell>
                              </TableRow>
                            </TableBody>
                          )
                        ) : null}
                      </Table>
                      {/* </div> */}
                    </TableContainer>
                  )}
                </div>
              </aside>
            </article>
          </section>
        </div>
      </div>
    </>
  );
};

export default TreeTable;
