import * as React from "react";
import PropTypes from "prop-types";
import SvgIcon from "@mui/material/SvgIcon";
import { styled } from "@mui/material/styles";
import Collapse from "@mui/material/Collapse";
import { useSpring, animated } from "@react-spring/web";
import { useState } from "react";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { Box, Modal, Button } from "@mui/material";
import { PostContext } from "./ContextApi";
import { TreeView } from "@mui/x-tree-view/TreeView";
import { TreeItem, treeItemClasses } from "@mui/x-tree-view/TreeItem";
import AxiosInstance from "../api/AxiosInstance";

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }}></Tooltip>
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "transparent",
    color: "#fff",
    minWidth: "maxContent",
    fontSize: theme.typography.pxToRem(12),
    // border: "1px solid #dadde9",
  },
  [`& .${tooltipClasses.arrow}`]: {
    // backgroundColor: "#F6CF9B",
    fill: "#58D68D",
    color: "#D2B",
  },
}));
function MinusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0C134F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M6 9l6 6 6-6" />
      </svg>
    </SvgIcon>
  );
}

function PlusSquare(props) {
  return (
    <SvgIcon fontSize="inherit" style={{ width: 24, height: 24 }} {...props}>
      tslint:disable-next-line: max-line-length
      <svg
        width="20"
        height="20"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0C134F"
        strokeWidth="4"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M9 18l6-6-6-6" />
      </svg>
    </SvgIcon>
  );
}

function CloseSquare(props) {
  return (
    <SvgIcon
      className="close"
      fontSize="inherit"
      style={{ width: 14, height: 14 }}
      {...props}
    >
      <svg
        width="14"
        height="14"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#0C134F"
        strokeWidth="4"
        strokeLinecap="butt"
        strokeLinejoin="round"
      >
        <circle cx="12" cy="12" r="10"></circle>
        <line x1="8" y1="12" x2="16" y2="12"></line>
      </svg>
    </SvgIcon>
  );
}
function TransitionComponent(props) {
  const style = useSpring({
    from: {
      opacity: 0,
      transform: "translate3d(20px,0,0)",
    },
    to: {
      opacity: props.in ? 1 : 0,
      transform: `translate3d(${props.in ? 0 : 20}px,0,0)`,
    },
  });

  return (
    <animated.div style={style}>
      <Collapse {...props} />
    </animated.div>
  );
}

TransitionComponent.propTypes = {
  in: PropTypes.bool,
};

const StyledTreeItem = styled(TreeItem)(({ rootNode }) => {
  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 32.5,
      background: "red",
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #27374D` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #27374D`,
    },
  };
});

const StyledTreeItem1 = styled(TreeItem)(({ rootNode }) => {
  return {
    position: "relative",
    "&:before": {
      pointerEvents: "none",
      content: '""',
      position: "absolute",
      width: 50,
      left: -18,
      top: 50,
      borderBottom:
        // only display if the TreeItem is not root node
        !rootNode ? `2px solid #27374D` : "none",
    },

    [`& .${treeItemClasses.group}`]: {
      marginLeft: 16,
      paddingLeft: 18,
      borderLeft: `2px solid #27374D`,
    },
  };
});
// style for model
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  height: 400,
  bgcolor: "background.paper",
  border: "1px solid #000",
  boxShadow: 24,
  borderRadius: "6px",
  p: 1,
};

let MasterTree = () => {
  const {
    expanded,
    setExpanded,
    organization,
    fetchMoreOrg,
    setBranches,
    fetchMoreBranch,
    fetchMoreBatch,
    setBatches,
    toggledNode,
    setToggledNode,
    handleSelect,
    branches,
    batches,
  } = React.useContext(PostContext);

  let [openModel, setOpenModel] = useState(false);

  const handleImageModelClose = () => {
    setOpenModel(false);
  };

  return (
    <div style={{ marginTop: "0%" }} id="mastreTree">
      {organization?.data?.length > 0 ? (
        <section id="tree2" style={{ paddingLeft: "0%" }}>
          <Modal open={openModel} onClose={handleImageModelClose}>
            <Box sx={style} style={{ position: "relative" }}>
              <Button
                onClick={handleImageModelClose}
                variant="contained"
                color="warning"
                style={{
                  position: "absolute",
                  right: "1%",
                }}
              >
                close
              </Button>
            </Box>
          </Modal>
          <TreeView
            aria-label="customized"
            className="treeCont"
            defaultCollapseIcon={<MinusSquare />}
            defaultExpandIcon={<PlusSquare />}
            defaultEndIcon={<CloseSquare />}
            sx={{
              flexGrow: 1,
              paddingLeft: "20px",
            }}
            onNodeToggle={(a, b) => {
              const action = b.length > toggledNode.length ? true : false;
              if (action) {
                const previousArray = toggledNode;
                const newValue =
                  toggledNode.length > 0
                    ? b.filter((e) => !previousArray.includes(e))[0]
                    : b[0];
                handleSelect("x", newValue);
                const modifiedArray =
                  newValue === ""
                    ? b
                    : b.filter(
                        (e) =>
                          !e.includes(
                            `@@${newValue.split("@@")[1].split("#")[0]}#`
                          )
                      );
                setToggledNode([...modifiedArray, newValue]);
              } else {
                setToggledNode(b);
              }
              setExpanded(b);
            }}
          >
            {
              <>
                {organization.loading ? (
                  "Loading..."
                ) : organization.data === null ? (
                  `${organization.error || "No data Found"}`
                ) : (
                  <>
                    {organization.data.map((org) => {
                      return (
                        <StyledTreeItem
                          style={{ paddingLeft: "20px" }}
                          className="subjectBranch"
                          nodeId={`subjects@@subject#${org.id}`}
                          key={`subjects@@subject#${org.id}`}
                          label={
                            <section
                              id="subjectBranch"
                              style={{ padding: "16px" }}
                            >
                              <span
                                title={`Organization - ${org.name}`}
                                style={{
                                  textTransform: "uppercase",
                                  color: "#3d72aa",
                                  fontWeight: "700",
                                  fontSize: "20.7px",
                                  marginTop: "50px",
                                  fontFamily: " 'Poppins', sans-serif",
                                }}
                              >
                                {org.name}
                              </span>
                            </section>
                          }
                        >
                          <>
                            {branches &&
                              Object.keys(branches).length > 0 &&
                              branches[org.id] && (
                                <>
                                  {branches[org.id].loading ? (
                                    <small
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                      }}
                                    >
                                      Loading..
                                    </small>
                                  ) : branches[org.id].data ? (
                                    <>
                                      {branches[org.id].data ? (
                                        branches[org.id].data.map((brn) => {
                                          return (
                                            <StyledTreeItem
                                              style={{ paddingLeft: "20px" }}
                                              className="subjectBranch"
                                              nodeId={`subject${org.id}-@@chapter#${brn.id}`}
                                              key={`subject${org.id}-@@chapter#${brn.id}`}
                                              label={
                                                <section
                                                  id="chapterBranch"
                                                  style={{
                                                    padding: "16px",
                                                    borderBottom:
                                                      "2px solid white",
                                                  }}
                                                >
                                                  <span
                                                    style={{
                                                      textTransform:
                                                        "capitalize",
                                                      color: "#000000b3",
                                                      fontSize: "20.7px",
                                                      fontWeight: "bold",
                                                      width: "15vw",
                                                      fontFamily:
                                                        " 'Poppins', sans-serif",
                                                    }}
                                                    title={`Branch - ${brn.name}`}
                                                  >
                                                    &nbsp; {brn.name}
                                                  </span>
                                                </section>
                                              }
                                            >
                                              <>
                                                {batches &&
                                                  Object.keys(batches).length >
                                                    0 &&
                                                  batches[brn.id] && (
                                                    <>
                                                      {batches[brn.id]
                                                        .loading ? (
                                                        <small
                                                          style={{
                                                            color: "purple",
                                                            fontWeight: "bold",
                                                          }}
                                                        >
                                                          Loading..
                                                        </small>
                                                      ) : batches[brn.id]
                                                          .data ? (
                                                        <>
                                                          {batches[brn.id]
                                                            .data ? (
                                                            batches[
                                                              brn.id
                                                            ].data.map(
                                                              (bat) => {
                                                                return (
                                                                  <StyledTreeItem
                                                                    style={{
                                                                      paddingLeft:
                                                                        "20px",
                                                                    }}
                                                                    // className="subjectBranch"
                                                                    nodeId={`subject${org.id}-chapter${brn.id}-@@topic#${bat.id}`}
                                                                    key={`subject${org.id}-chapter#${brn.id}-@@topic#${bat.id}`}
                                                                    label={
                                                                      <section
                                                                        id="chapterBranch"
                                                                        className="chapterBranch"
                                                                        style={{
                                                                          padding:
                                                                            "16px",
                                                                          borderBottom:
                                                                            "2px solid white",
                                                                          color:
                                                                            "#0000ffa1",
                                                                          fontWeight:
                                                                            "bold",
                                                                          fontSize:
                                                                            "20.7px",
                                                                        }}
                                                                      >
                                                                        <span
                                                                          style={{
                                                                            textTransform:
                                                                              "capitalize",
                                                                          }}
                                                                          title={`Batch - ${bat.name}`}
                                                                        >
                                                                          &nbsp;{" "}
                                                                          {
                                                                            bat.name
                                                                          }
                                                                        </span>
                                                                      </section>
                                                                    }
                                                                  >
                                                                    {/* next tree child part */}
                                                                  </StyledTreeItem>
                                                                );
                                                              }
                                                            )
                                                          ) : (
                                                            <small
                                                              style={{
                                                                color: "purple",
                                                                fontWeight:
                                                                  "bold",
                                                                padding: "17px",
                                                                margin: "10px",
                                                              }}
                                                            >
                                                              no batches found
                                                            </small>
                                                          )}
                                                          {batches[brn.id]
                                                            .length > 10 &&
                                                            batches[brn.id]
                                                              .length /
                                                              batches[brn.id]
                                                                .data.length >
                                                              1 && (
                                                              <button
                                                                disabled={
                                                                  batches[
                                                                    brn.id
                                                                  ].loadMore
                                                                }
                                                                onClick={() => {
                                                                  fetchMoreBatch(
                                                                    brn.id,
                                                                    batches,
                                                                    setBatches,
                                                                    "/report/batchreport/",
                                                                    "branch_id"
                                                                  );
                                                                }}
                                                                title={
                                                                  !batches[
                                                                    brn.id
                                                                  ].loadMore
                                                                    ? "Click to loadMore Batches"
                                                                    : ""
                                                                }
                                                                style={{
                                                                  border:
                                                                    "none",
                                                                  background:
                                                                    "transparent",
                                                                  // padding: "5px",
                                                                  color:
                                                                    "purple",
                                                                  fontWeight:
                                                                    "bolder",
                                                                  fontSize:
                                                                    "small",
                                                                  textDecoration:
                                                                    !batches[
                                                                      brn.id
                                                                    ].loadMore
                                                                      ? "underline"
                                                                      : "none",
                                                                  cursor:
                                                                    !batches[
                                                                      brn.id
                                                                    ].loadMore
                                                                      ? "pointer"
                                                                      : "not-allowed",
                                                                  position:
                                                                    "absolute",
                                                                  bottom:
                                                                    "-5px",
                                                                  // right: "0px",
                                                                  zIndex: "555",
                                                                  left: "70%",
                                                                  marginBottom:
                                                                    "2%",
                                                                }}
                                                              >
                                                                {batches[brn.id]
                                                                  .loadMore
                                                                  ? "Loading..."
                                                                  : "Load More batches"}
                                                              </button>
                                                            )}
                                                        </>
                                                      ) : (
                                                        <small
                                                          style={{
                                                            display:
                                                              "inline-block",
                                                            color: "purple",
                                                            fontWeight: "bold",
                                                            padding: "17px",
                                                            fontSize: "20px",
                                                            // margin:"10px"
                                                          }}
                                                        >
                                                          {batches[brn.id]
                                                            .error ||
                                                            "No data found"}
                                                        </small>
                                                      )}
                                                    </>
                                                  )}
                                              </>
                                            </StyledTreeItem>
                                          );
                                        })
                                      ) : (
                                        <small
                                          style={{
                                            color: "purple",
                                            fontWeight: "bold",
                                            padding: "18.1px",
                                            margin: "10px",
                                          }}
                                        >
                                          no branch found
                                        </small>
                                      )}
                                      {branches[org.id].length > 25 &&
                                        branches[org.id].length /
                                          branches[org.id].data.length >
                                          1 && (
                                          <button
                                            disabled={branches[org.id].loadMore}
                                            onClick={() => {
                                              fetchMoreBranch(
                                                org.id,
                                                branches,
                                                setBranches,
                                                "/report/branchreport/",
                                                "organization_id"
                                              );
                                            }}
                                            title={
                                              !branches[org.id].loadMore
                                                ? "Click to loadMore Branches"
                                                : ""
                                            }
                                            style={{
                                              border: "none",
                                              background: "transparent",
                                              // padding: "5px",
                                              color: "purple",
                                              fontWeight: "bolder",
                                              fontSize: "small",
                                              textDecoration: !branches[org.id]
                                                .loadMore
                                                ? "underline"
                                                : "none",
                                              cursor: !branches[org.id].loadMore
                                                ? "pointer"
                                                : "not-allowed",
                                              position: "absolute",
                                              bottom: "-5px",
                                              // right: "0px",
                                              zIndex: "555",
                                              left: "70%",
                                              marginBottom: "2%",
                                            }}
                                          >
                                            {branches[org.id].loadMore
                                              ? "Loading..."
                                              : "Load More Branches"}
                                          </button>
                                        )}
                                    </>
                                  ) : (
                                    <small
                                      style={{
                                        color: "purple",
                                        fontWeight: "bold",
                                        padding: "16.5px",
                                        margin: "10px",
                                        fontSize: "20px",
                                      }}
                                    >
                                      {branches[org.id].error ||
                                        "No data found"}
                                    </small>
                                  )}
                                </>
                              )}
                          </>
                        </StyledTreeItem>
                      );
                    })}
                    {organization.length > 10 &&
                      organization.length / organization.data.length > 1 && (
                        <button
                          disabled={organization.loadMore}
                          onClick={() => {
                            fetchMoreOrg();
                          }}
                          title={
                            !organization.loadMore &&
                            "Click to loadMore Organizations"
                          }
                          style={{
                            border: "none",
                            background: "transparent",
                            padding: "5px",
                            color: "rgb(48, 143, 174)",
                            fontWeight: "bolder",
                            fontSize: "small",
                            textDecoration: !organization.loadMore
                              ? "underline"
                              : "none",
                            cursor: !organization.loadMore
                              ? "pointer"
                              : "not-allowed",
                          }}
                        >
                          {organization.loadMore
                            ? "Loading..."
                            : "Load More organization"}
                        </button>
                      )}
                  </>
                )}
              </>
            }
          </TreeView>
        </section>
      ) : (
        ""
      )}
    </div>
  );
};
export default MasterTree;
