import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import "./App.css";
import Login from "./component/auth/Login";
import GlobalContext from "./GlobalContext";
import { ToastContainer } from "react-toastify";
// import TreeTable from "./reporttree/TreeTable";
import LandingPage from "./reporttree/LandingPage";
import Navbar from "./component/navbar/Navbar";
import StudentSearchTable from "./student_count/StudentSearchTable";
// import ShrinkingTables from "./reporttree/ShrinkingTables";
// import ReportTree from "./reporttree/ReportTree";
// import ShrinkingTables from "./reporttree/ShrinkingTables";

function App() {
  const token = window.sessionStorage.getItem("token");
  return (
    <div className="App">
      <GlobalContext>
        <Router>
          <Navbar/>
          <ToastContainer
            theme="dark"
            autoClose={2000}
            style={{ zIndex: "1111111111111111111" }}
            position="top-right"
          />

          <Routes>
            {token === null ? (
              <>
                <Route path="/" element={<Login />} />
              </>
            ) : (
              <>
                <Route path="/" element={<LandingPage />} />
              </>
            )}
            <Route path="/report" element={<LandingPage />} />
            {/* <Route path="reporttree" element={<ReportTree/>}/> */}
            <Route path="/table" element={<StudentSearchTable/>}/>
          </Routes>
        </Router>
      </GlobalContext>
    </div>
  );
}

export default App;
