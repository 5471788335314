import React from "react";
import { Link, useNavigate } from "react-router-dom";
import styles from "./nav.module.css";
import { Button } from "@mui/material";

const Navbar = () => {

  const logout = () => {
    sessionStorage.clear();
    window.location.href = "/";
    window.history.replaceState(null, null, "/")
  };
  const token = window.sessionStorage.getItem("token");
  return (
    <div className={styles.navbarContainer}>
      <h1 style={{ color: "white", fontSize: "30px", marginLeft: "20px" }}>
        REPORT ANALYST
      </h1>
      {token !== null && (
        <Button onClick={logout}>
          <Link
            to="/"
            className={styles.button}
            style={{ color: "white", textDecoration: "none" }}
          >
            Logout
          </Link>
        </Button>
      )}
    </div>
  );
};

export default Navbar;
