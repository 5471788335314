import * as React from "react";
import {
  Box,
  Typography,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Pagination,
  Card,
  CardContent,
  Divider,
  Grid,
} from "@mui/material";
import Loader from "../component/loader/Loader";
import PaidSharpIcon from "@mui/icons-material/PaidSharp";
import SchoolSharpIcon from "@mui/icons-material/SchoolSharp";
import AxiosInstance from "../api/AxiosInstance";
import GroupsSharpIcon from "@mui/icons-material/GroupsSharp";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import "./Academic.css";
// import Mockdetails from "./Mockdetails";
import { toast } from "react-toastify";
import { Suspense } from "react";
import BatchDetailes from "./BatchDetailes";
import FeesDeatiles from "./FeesDeatiles";
const Requirement = React.lazy(() => import("./Requirement"));
const Mockdetails = React.lazy(() => import("./Mockdetails"));

// const HtmlTooltip = styled(({ className, ...props }) => (
//   <Tooltip {...props} classes={{ popper: className }} placement="right" />
// ))(({ theme }) => ({
//   [`& .${tooltipClasses.tooltip}`]: {
//     backgroundColor: "#f5f5f9",
//     color: "rgba(0, 0, 0, 0.87)",
//     maxWidth: 220,
//     fontSize: theme.typography.pxToRem(12),
//     border: "1px solid #dadde9",
//   },
// }));

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "50%",
  bgcolor: "white",
  border: "2px solid #000",
  boxShadow: 24,
  p: 4,
};

export default function StudentCount(props) {
  let [regdStudents, setRegdStudents] = React.useState([]);
  const [defaultStudent, setDefaultStudent] = React.useState([]);
  const [loadStu, setLoadStu] = React.useState(false);
  const [pagination, setPagination] = React.useState({
    limit: 25,
    page: 1,
    load: false,
    length: 1,
  });

  let level = props.quizAttendedLevel;
  let selectedParam = props.selectedParam;
  let appointment_id = props.appointment_idx;

  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [page, setPage] = React.useState(1);
  const [totalLength, setTotalLength] = React.useState(0);

  const [eduopen, setEduOpen] = React.useState(false);
  const [feeopen, setFeeOpen] = React.useState(false);
  const [batchopen, setBatchOpen] = React.useState(false);
  const [mockopen, setMockOpen] = React.useState(false);
  const [reqopen, setReqOpen] = React.useState(false);

  const handleOpen1 = (modalType) => {
    if (modalType === "eduopen") {
      setEduOpen(true);
    } else if (modalType === "feeopen") {
      setFeeOpen(true);
    } else if (modalType === "batchopen") {
      setBatchOpen(true);
    } else if (modalType === "mockopen") {
      setMockOpen(true);
    } else if (modalType === "reqopen") {
      setReqOpen(true);
    }
  };

  const handleCloseEdu = () => setEduOpen(false);
  const handleCloseFee = () => setFeeOpen(false);
  const handleCloseBatch = () => setBatchOpen(false);
  const handleCloseMock = () => {
    setSelectedMock(null);
    setMockOpen(false);
  };
  const handleCloseReq = () => setReqOpen(false);

  const [selectedFees, setSelectedFees] = React.useState(null);
  const [selectedAcademic, setSelectedAcademic] = React.useState(null);
  const [selectedBatch, setSelectedBatch] = React.useState(null);
  const [selectedmock, setSelectedMock] = React.useState(null);
  const [selectedReq, setSelectedReq] = React.useState(null);

  console.log(selectedBatch, "sel");
  const style1 = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "35%",
    // bgcolor: "white",
    // border: "2px solid #000",
    // boxShadow: 24,
    // p: 4,
  };

  // let QuizAttendedValue =
  //   level === "1"
  //     ? `&org_id=${appointment_id}`
  //     : level === "2"
  //     ? `&branch_id=${appointment_id}`
  //     : level === "3"
  //     ? `&batch_id=${appointment_id}`
  //     : "";

  const [loadReg, setLoadRed] = React.useState(false);

  // const handleStateOpen = async (e) => {
  //   // handleOpen();
  //   setLoadRed(true);
  //   try {
  //     let response = await AxiosInstance.get(
  //       `/report/org-studcount-list/?st_count=true${QuizAttendedValue}`
  //     );
  //     setRegdStudents(response.data.results);
  //     // console.log(response,"response")
  //     setLoadRed(false);
  //   } catch (error) {
  //     setLoadRed(false);
  //     console.log(error);
  //   }
  // };

  React.useEffect(() => {
    const fetchData = async () => {
      let orgID = `?org_id`;
      let QuizAttendedValue =
        level === "1"
          ? `${orgID}=${appointment_id}`
          : level === "2"
          ? `?branch_id=${appointment_id}`
          : level === "3"
          ? `?batch_id=${appointment_id}`
          : "";
      handleOpen();
      setLoadRed(true);
      const url1 = `/hr/studentbranchlist/`;
      const params= selectedParam?`&${selectedParam}`:""
      try {
        let response = await AxiosInstance.get(
          `${url1}${QuizAttendedValue}${params}`
        );
        // console.log(response, "");
        setRegdStudents(response.data.results);
        setDefaultStudent(response.data.results);
        setPagination({
          ...pagination,
          length: response.data.total,
          load: response.data.total > 25 ? false : null,
          page: 1,
          limit: response.data.per_page,
        });
        // console.log(response,"response")
        setLoadRed(false);
      } catch (error) {
        setLoadRed(null);
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const handleChangePage = async (_, value) => {
    setPagination({
      ...pagination,
      page: value,
      load: true,
    });
    // setLoadRed(true);
    const newPage = value;
    let QuizAttendedValue =
      level === "1"
        ? `org_id=${appointment_id}`
        : level === "2"
        ? `branch_id=${appointment_id}`
        : level === "3"
        ? `batch_id=${appointment_id}`
        : "";
    setPagination({ ...pagination, load: true });
    let url1 = `/hr/studentbranchlist/?page=${newPage}`;

    if (QuizAttendedValue) {
      url1 += `&${QuizAttendedValue}`;
    }
    
    if (selectedParam) {
      url1 += `&${selectedParam}`;
    }
    
    try {
      const { data } = await AxiosInstance.get(url1);
    

      setRegdStudents(data.results);
      setPage(newPage)
      // setLoadRed(false);

      setPagination({
        ...pagination,
        length: data.total,
        page: value,
        load: false,
      });
    } catch (error) {
      toast.error("something went wrong");
      setPagination({ ...pagination, load: false });
      // setLoadRed(false);
    }
  };

  const calculateSlNo = (index) => {
    return (page - 1) * 25 + index + 1;
  };

  // const totalPages = Math.ceil(totalLength / 10);

  return (
    <main>
      {loadReg === null ? (
        <h3>Something went wrong</h3>
      ) : loadReg ? (
        "Loading..."
      ) : (
        <>
          {/* modal for Acadamic*/}
          <Modal keepMounted open={eduopen} onClose={handleCloseEdu}>
            <Box sx={style1}>
              <Card className="academic-card">
                <CardContent>
                  <Typography
                    variant="h5"
                    gutterBottom
                    style={{
                      color: "rgb(48, 143, 174)",
                      fontWeight: "bold",
                      textAlign: "center",
                    }}
                  >
                    Academic Details
                  </Typography>
                  <Divider />
                  <Typography
                    variant="body1"
                    paragraph
                    style={{ textAlign: "center" }}
                  >
                    <strong>Name</strong> {selectedAcademic?.name}
                    <br />
                  </Typography>
                  <div
                    style={{ display: "flex", justifyContent: "space-around" }}
                  >
                    <button
                      onClick={handleCloseEdu}
                      style={{ position: "absolute", right: 0, top: 0 }}
                    >
                      <svg
                        width="27.81px"
                        height="30px"
                        viewBox="0 0 1216 1312"
                      >
                        <path
                          fill="red"
                          d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                        ></path>
                      </svg>
                    </button>
                    <aside>
                      <Typography variant="body1" paragraph>
                        <strong>Class 10 details:</strong>
                        <br />
                        {selectedAcademic?.percentage_10}% <br />
                        {selectedAcademic?.yop_10}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Class 12 details:</strong>
                        <br />
                        {selectedAcademic?.percentage_12}% <br />
                        {selectedAcademic?.yop_12}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Diploma/ITI:</strong>
                        <br />
                        {Number.parseInt(selectedAcademic?.percentage_iti) <
                          1 &&
                        Number.parseInt(selectedAcademic?.percentage_dip) <
                          1 ? (
                          "---"
                        ) : (
                          <>
                            {Number.parseInt(selectedAcademic?.percentage_iti) <
                            1 ? null : (
                              <>
                                {" "}
                                ITI- {selectedAcademic?.percentage_iti}% <br />
                                {selectedAcademic?.yop_iti}
                              </>
                            )}
                            {Number.parseInt(selectedAcademic?.percentage_dip) <
                            1 ? null : (
                              <>
                                {" "}
                                DIP- {selectedAcademic?.percentage_dip}% <br />
                                {selectedAcademic?.yop_dip}
                              </>
                            )}
                          </>
                        )}
                      </Typography>
                    </aside>
                    <aside>
                      <Typography variant="body1" paragraph>
                        <strong>Degree:</strong>
                        <br />
                        {selectedAcademic?.degree?.name || "No Data"} <br />
                        {selectedAcademic?.percentage_deg}% <br />
                        {selectedAcademic?.d_stream?.name || "No Data"} <br />
                        {selectedAcademic?.yop_deg}
                      </Typography>
                      <Typography variant="body1" paragraph>
                        <strong>Masters:</strong> <br />
                        {selectedAcademic?.masters?.name || "No Data"} <br />
                        {selectedAcademic?.m_stream?.name || "No Data"} <br />
                        {selectedAcademic?.percentage_mas}%
                      </Typography>
                    </aside>
                  </div>
                </CardContent>
              </Card>

              {/* <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "30px", fontWeight: "bold" }}
              >
                Academic Details
              </Typography>
              <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
                <Table
                  sx={{
                    borderCollapse: "separate",
                    tableLayout: "fixed",
                  }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead sx={{ backgroundColor: "#495579" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#495579",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        10% Year Of Passout
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#495579",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        12% Year Of Passout
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#495579",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Diploma<span>/</span>ITI % Year Of Passout
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#495579",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Degree
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "#fff",
                          backgroundColor: "#495579",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Masters
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflow: "auto" }}>
                    {selectedAcademic === null ? (
                      "Loading..."
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": {
                            border: 0,
                          },
                        }}
                      >
                        <TableCell align="center">
                          {selectedAcademic.percentage_10}% <br />
                          {selectedAcademic.yop_10}
                        </TableCell>
                        <TableCell align="center">
                          {selectedAcademic.percentage_12}% <br />
                          {selectedAcademic.yop_12}
                        </TableCell>
                        <TableCell align="center">
                          {Number.parseInt(selectedAcademic.percentage_iti) <
                            1 &&
                          Number.parseInt(selectedAcademic.percentage_dip) <
                            1 ? (
                            "---"
                          ) : (
                            <>
                              {Number.parseInt(
                                selectedAcademic.percentage_iti
                              ) < 1 ? null : (
                                <>
                                  {" "}
                                  ITI- {selectedAcademic.percentage_iti}% <br />
                                  {selectedAcademic.yop_iti}
                                </>
                              )}
                              {Number.parseInt(
                                selectedAcademic.percentage_dip
                              ) < 1 ? null : (
                                <>
                                  {" "}
                                  DIP- {selectedAcademic.percentage_dip}% <br />
                                  {selectedAcademic.yop_dip}
                                </>
                              )}
                            </>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <b style={{ color: "skyblue" }}>
                                  {"DegreeType:"}
                                </b>{" "}
                                <br />{" "}
                                <b>
                                  {selectedAcademic.degree?.name || "No Data"}
                                </b>
                                <br />
                                <b style={{ color: "skyblue" }}>
                                  {"Stream:"}
                                </b>{" "}
                                <br />{" "}
                                <b>
                                  {selectedAcademic.d_stream?.name || "No Data"}
                                </b>
                              </React.Fragment>
                            }
                          >
                            {selectedAcademic.percentage_deg}% <br />
                            {selectedAcademic.degree?.short_form
                              ? selectedAcademic.degree?.short_form
                              : "----"}
                            <br />
                            {selectedAcademic.d_stream?.short_form
                              ? selectedAcademic.d_stream?.short_form
                              : "----"}{" "}
                            <br /> {selectedAcademic.yop_deg}
                          </HtmlTooltip>
                        </TableCell>

                        <TableCell align="center">
                          <HtmlTooltip
                            title={
                              <React.Fragment>
                                <b style={{ color: "skyblue" }}>
                                  {"DegreeType:"}
                                </b>{" "}
                                <br />{" "}
                                <b>
                                  {selectedAcademic.masters?.name || "No Data"}
                                </b>
                                <br />
                                <b style={{ color: "skyblue" }}>
                                  {"Stream:"}
                                </b>{" "}
                                <br />{" "}
                                <b>
                                  {selectedAcademic.m_stream?.name || "No Data"}
                                </b>
                              </React.Fragment>
                            }
                          >
                            {selectedAcademic.percentage_mas}% <br />
                            {selectedAcademic.masters?.short_form
                              ? selectedAcademic.masters?.short_form
                              : "----"}
                            <br />
                            {selectedAcademic.m_stream?.short_form
                              ? selectedAcademic.m_stream?.short_form
                              : "----"}{" "}
                          </HtmlTooltip>
                        </TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
              </TableContainer> */}
            </Box>
          </Modal>

          {/* modal for batch view  */}
          <Modal keepMounted open={batchopen} onClose={handleCloseBatch}>
            <Box sx={style}>
              <button
                onClick={handleCloseBatch}
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                <svg width="27.81px" height="30px" viewBox="0 0 1216 1312">
                    <path
                      fill="red"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
              </button>

              <>
                <Suspense fallback={"Loading"}>
                  <BatchDetailes data={selectedBatch} />
                </Suspense>
              </>
            </Box>
          </Modal>

          {/* modal for fee  */}
          <Modal
            keepMounted
            open={feeopen}
            onClose={handleCloseFee}
            aria-labelledby="keep-mounted-modal-title"
            aria-describedby="keep-mounted-modal-description"
          >
            <Box sx={style}>
              <button
                onClick={handleCloseFee}
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                <svg width="27.81px" height="30px" viewBox="0 0 1216 1312">
                    <path
                      fill="red"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
              </button>

              <>
                <Suspense fallback={"Loading"}>
                  <FeesDeatiles data={selectedFees} />
                </Suspense>
              </>
            </Box>
          </Modal>

          {/* modal for mock details */}
          <Modal keepMounted open={mockopen} onClose={handleCloseMock}>
            <Box sx={style}>
              <button
                onClick={handleCloseMock}
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                 <svg width="27.81px" height="30px" viewBox="0 0 1216 1312">
                    <path
                      fill="red"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
              </button>

              <>
                <Suspense fallback={"Loading"}>
                  <Mockdetails data={selectedmock} />
                </Suspense>
              </>
            </Box>
          </Modal>

          {/* modal for Requirement */}

          <Modal keepMounted open={reqopen} onClose={handleCloseReq}>
            <Box sx={style}>
              <button
                onClick={handleCloseReq}
                style={{ position: "absolute", right: 0, top: 0 }}
              >
                <svg width="27.81px" height="30px" viewBox="0 0 1216 1312">
                    <path
                      fill="red"
                      d="M1202 1066q0 40-28 68l-136 136q-28 28-68 28t-68-28L608 976l-294 294q-28 28-68 28t-68-28L42 1134q-28-28-28-68t28-68l294-294L42 410q-28-28-28-68t28-68l136-136q28-28 68-28t68 28l294 294l294-294q28-28 68-28t68 28l136 136q28 28 28 68t-28 68L880 704l294 294q28 28 28 68"
                    ></path>
                  </svg>
              </button>

              <>
                <Suspense fallback={"Loading"}>
                  <Requirement data={selectedReq} />
                </Suspense>
              </>
            </Box>
          </Modal>

          {/* model for student details */}
          <Typography
            id="modal-modal-title"
            align="center"
            variant="h6"
            component="h2"
            sx={{
              fontSize: "30px",
              fontWeight: "bold",
              color: "rgb(48, 143, 174)",
            }}
          >
            Student Details
          </Typography>
          <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
            <Table
              sx={{ borderCollapse: "separate", tableLayout: "fixed" }}
              aria-label="simple table"
              stickyHeader
            >
              <TableHead>
                <TableRow>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",

                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Sl No
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Name
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: " 250px !important",
                    }}
                  >
                    Mobile
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Email
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Academic Details
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Fees
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Batch details
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Mock details
                  </TableCell>
                  <TableCell
                    align="center"
                    sx={{
                      color: "black",
                      backgroundColor: "lightgray",
                      // backgroundColor: "#495579",
                      fontWeight: "600",
                      fontSize: "19px",
                      width: "300px !important",
                    }}
                  >
                    Requirement Count
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody style={{ overflow: "auto" }}>
                {pagination.load ? (
                  <div
                    style={{
                      width: "80vw",
                      height: "40vh",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Loader />
                  </div>
                ) : regdStudents.length === 0 ? (
                  <TableRow>
                    <TableCell colSpan={8}>No Data Found</TableCell>
                  </TableRow>
                ) : (
                  regdStudents?.map((value, index) => {
                    console.log(value,"value")
                    return (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                        key={index}
                      >
                        <TableCell align="center">
                          {calculateSlNo(index)}
                        </TableCell>
                        <TableCell align="center">{value.name}</TableCell>
                        <TableCell align="center">
                          {value?.number?.map((val) => {
                            return val.number + " ";
                          })}
                        </TableCell>
                        <TableCell align="center">
                          {value?.email?.map((val) => {
                            return val.email + " ";
                          })}
                        </TableCell>
                        {/* //! Academic details */}
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setSelectedAcademic(value);
                              handleOpen1("eduopen");
                            }}
                          >
                            <SchoolSharpIcon style={{ color: "black" }} />
                          </Button>
                        </TableCell>
                        {/* //! fees details */}
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setSelectedFees(value.id);
                              handleOpen1("feeopen");
                            }}
                          >
                            <PaidSharpIcon style={{ color: "black" }} />
                          </Button>
                        </TableCell>
                        {/* //! batch details */}
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setSelectedBatch(value.id);
                              handleOpen1("batchopen");
                            }}
                           >
                            <GroupsSharpIcon style={{ color: "black" }} />
                          </Button>
                        </TableCell>
                        {/*//! mock details */}
                        <TableCell align="center">
                          <Button
                            onClick={() => {
                              setSelectedMock(value.id);
                              handleOpen1("mockopen");
                            }}
                          >
                            <MenuBookIcon style={{ color: "black" }} />
                          </Button>
                        </TableCell>

                        {/* //! reuirement deatils */}

                        <TableCell align="center">
                          {value.req_schedule_count ? (
                            <span
                              style={{
                                color: "black",
                                textDecoration: "underline",
                                fontWeight: "bold",
                                cursor: "pointer",
                              }}
                              onClick={() => {
                                setSelectedReq(value.id);
                                handleOpen1("reqopen");
                              }}
                            >
                              {value.req_schedule_count}
                            </span>
                          ) : (
                            "----"
                          )}
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
          </TableContainer>
          <aside
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "1%",
              marginBottom: "1%",
            }}
          >
            {pagination.load !== null && (
              <Pagination
                count={Math.ceil(pagination.length / pagination.limit)}
                color="primary"
                defaultPage={1}
                disabled={pagination.load}
                rowsperpage={pagination.limit}
                siblingCount={1}
                showLastButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                showFirstButton={
                  Math.ceil(pagination.length / pagination.limit) > 5
                }
                page={pagination.page}
                onChange={handleChangePage}
              />
            )}
          </aside>
        </>
      )}
    </main>
  );
}
