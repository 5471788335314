import React, { useState, useEffect } from "react";
import Loader from "../component/loader/Loader";
import { Modal, Pagination, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import AxiosInstance from "../api/AxiosInstance";
import { toast } from "react-toastify";
import "./Academic.css";

const FeesDeatiles = (props) => {
  console.log(props, "propsb");

  const [feesDetails, setFeesDetails] = useState([]);
  const [defaultfees,setDefaultFees] = useState([]);
  const [load, setload] = useState(false);
  const [pagination, setPagination] = React.useState({
    limit: 25,
    page: 1,
    load: false,
    length: 1,
  });

  // console.log(feesDetails, "req");

  useEffect(() => {
    const fetchData = async () => {
      setload(true);
      try {
        await AxiosInstance.get(
          `/hr/student-fee-list/?stu_id=${props.data}`
        )
          .then((response) => {
            console.log(response);
            setFeesDetails(response.data.results);
            setDefaultFees(response.data);
            setload(false);
            setPagination({
              ...pagination,
              length: response.data.total,
              load: response.data.total > 25 ? false : null,
              page: 1,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } catch {
        setload(null);
      }
    };
    if (props.data) fetchData();
  }, [props.data]);

  const handleChangePage = async (_, value) => {
    setPagination({
      ...pagination,
      page: value,
      load: true,
    });
    // setLoadRed(true);
    const newPage = value;

    setPagination({ ...pagination, load: true });
    try {
      const { data } = await AxiosInstance.get(
        `/hr/student-fee-list/?stu_id=${props.data}&page=${newPage}`
      );

      setFeesDetails(data.results);
      // setLoadRed(false);

      setPagination({
        ...pagination,
        length: data.total,
        page: value,
        load: false,
      });
    } catch (error) {
      toast.error("something went wrong");
      setPagination({ ...pagination, load: false });
      // setLoadRed(false);
    }
  };

  return (
    <>
          <Typography
                id="modal-modal-title"
                variant="h6"
                component="h2"
                sx={{ fontSize: "30px", fontWeight: "bold", color: "rgb(48, 143, 174)",textAlign:"center" }}
              >
                Fee Details
              </Typography>
              <TableContainer component={Paper} sx={{ maxHeight: 450 }}>
                <Table
                  sx={{
                    borderCollapse: "separate",
                    tableLayout: "fixed",
                  }}
                  aria-label="simple table"
                  stickyHeader
                >
                  <TableHead sx={{ backgroundColor: "lightgray" }}>
                    <TableRow>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Course Name
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Target Amount
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Paid Amount
                      </TableCell>
                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Balance Amount
                      </TableCell>

                      <TableCell
                        align="center"
                        sx={{
                          color: "black",
                          backgroundColor: "lightgray",
                          fontWeight: "600",
                          fontSize: "19px",
                          width: "300px !important",
                        }}
                      >
                        Percentage
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody style={{ overflow: "auto" }}>
            {pagination.load ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  <Loader />
                </TableCell>
              </TableRow>
            ) : load === null ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  Something went wrong
                </TableCell>
              </TableRow>
            ) : feesDetails?.length === 0 ? (
              <TableRow>
                <TableCell colSpan={3} align="center">
                  No data found
                </TableCell>
              </TableRow>
            ) : feesDetails?.map((value, index) => {
                          return (
                            <TableRow
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                              key={index}
                            >
                              <TableCell align="center">
                                {value.course?.name
                                  ? value.course?.name
                                  : "----"}
                              </TableCell>
                              <TableCell align="center">
                                {value.target_fee ? value.target_fee : "----"}
                              </TableCell>
                              <TableCell align="center">{value.paid}</TableCell>
                              <TableCell align="center">
                                {value.balance_fee ? value.balance_fee : "----"}
                              </TableCell>
                              <TableCell align="center">
                                {value.per ? value.per : "----"}
                              </TableCell>
                            </TableRow>
                          );
                        })}
                  </TableBody>
                </Table>
              </TableContainer>
  
    <aside
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "1%",
        marginBottom: "1%",
      }}
    >
        {pagination.load !== null && (
          <Pagination
            count={Math.ceil(pagination.length / pagination.limit)}
            color="primary"
            defaultPage={1}
            disabled={pagination.load}
            rowsperpage={pagination.limit}
            siblingCount={1}
            showLastButton={Math.ceil(pagination.length / pagination.limit) > 5}
            showFirstButton={
              Math.ceil(pagination.length / pagination.limit) > 5
            }
            page={pagination.page}
            onChange={handleChangePage}
          />
        )}
      </aside>
   </>
  );
};

export default FeesDeatiles;

